import React from "react";
import { Link } from "react-router-dom";
import colors from "../../styles/color";

const ProductCard = ({ product }) => {
  return (
    <Link to={`/products/${product.product_id}`} className="block">
      <div
        className="border flex flex-col items-center sm:w-[18rem] w-[10rem] h-[24rem] sm:h-[32rem] sm:mx-3 overflow-hidden rounded-md shadow-lg cursor-pointer"
        style={{ backgroundColor: colors.BodyBg }}
      >
        <div className="w-full h-[80%]">
          <img
            className="object-cover object-top w-full h-full"
            src={product.main_image}
            alt="product-illustration"
          />
        </div>

        <div className="p-4 h-[20%] w-full text-center">
          <h3 className="sm:text-[16px] text-[10px] font-normal text-gray-600 hover:text-[#EDB92B] overflow-hidden text-ellipsis whitespace-nowrap line-clamp-2">
            {product.product_name}
          </h3>

          <p className="mt-2 text-sm text-gray-500">₹{product.actual_price}</p>
        </div>
      </div>
    </Link>
  );
};

export default ProductCard;
