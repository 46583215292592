import Carousel01 from "../../Assets/images/carousel01.png";
import Carousel02 from "../../Assets/images/carousel02.png";
import Carousel03 from "../../Assets/images/carousel03.png";

export const MainCarouselData = [
    {
        image: Carousel01,
        path: "/women/clothing/lengha_choli"
    },
    {
        image: Carousel02,
        path: "/women/clothing/women_dress",
        href: "https://maps.app.goo.gl/Fokot2Q2M4fq8tmC7"
    },
    {
        image: Carousel03,
        path: "/women/clothing/women_dress"
    },
];
