import React from "react";
import CartItem from "./CartItem";
import { useNavigate } from "react-router-dom";

const Cart = ({ cartItems, handleRemoveCartItem }) => {
  const navigate = useNavigate();

  // Calculate total price, total discount, and number of items
  const totalPrice = cartItems.reduce(
    (total, item) => total + item.quantity * item.product_price,
    0
  );

  const totalDiscount = cartItems.reduce(
    (total, item) =>
      total + (item.quantity * item.discount * item.product_price) / 100,
    0
  );

  const numberOfCartItems = cartItems.length;

  // Prepare the cartData array with relevant details from cartItems
  const cartData = cartItems.map(item => ({
    actual_price: item.actual_price,
    discount: item.discount,
    product_price: item.product_price,
    size: item.size,
    product_name: item.product_name,
    main_image: item.main_image,
    product_id: item.product_id,
    quantity: item.quantity,
    user_id: item.user_id,
  }));

  const handleCheckout = () => {
    navigate("/checkout", {
      state: { cartData, totalPrice, totalDiscount, numberOfCartItems },
    });
  };

  return (
    <div className="my-5">
      {/* <h1 className="text-[24px] font-semibold text-start mt-4 ml-[4em]  text-[#A5320C]">My Cart (03)</h1> */}
      <div className="relative grid-cols-3 lg:grid lg:px-16">
        <div className="col-span-2">
          {cartItems.map((cartItem, index) => (
            <CartItem
              key={`${cartItem.cart_id}-${index}`}
              cartItem={cartItem}
              handleRemoveCartItem={handleRemoveCartItem}
            />
          ))}
        </div>

        <div className="sticky top-0 px-5 mt-5 lg:mt-5">
          <div className="p-4 border shadow-lg">
            <p className="pb-4 font-bold text-black opacity-60">
              PRICE DETAILS
            </p>

            <hr />

            <div className="flex items-center justify-between mt-4 font-semibold">
              <p>Price ({cartItems.length} items)</p>
              <p>₹{totalPrice}</p>
            </div>

            <div className="flex items-center justify-between mt-2 font-semibold">
              <p>Discount</p>
              <p className="text-green-600 opacity-80">-₹{totalDiscount}</p>
            </div>

            <div className="flex items-center justify-between mt-2 mb-4 font-semibold">
              <p>Delivery Charge</p>
              <p className="text-green-600 opacity-80">Free</p>
            </div>

            <hr />

            <div className="flex items-center justify-between">
              <p className="mt-4 font-bold text-black ">Total Amount</p>
              <p className="font-semibold text-green-600">
                ₹{totalPrice - totalDiscount}
              </p>
            </div>

            <button
              className="w-full mt-8 h-[2.5rem] rounded-sm bg-[#E14E1D] text-white font-semibold hover:bg-[#A5320C] text-center"
              onClick={handleCheckout}
            >
              CHECKOUT
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cart;
