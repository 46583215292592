import React from "react";
import { Grid, Box, TextField } from "@mui/material";
// import razorPay from "../../Assets/images/razor-pay.png";
import { useLocation, useNavigate } from "react-router-dom";

const DeliveryAddressForm = ({
  cartData,
  totalPrice,
  totalDiscount,
  numberOfCartItems,
}) => {
  const token = localStorage.getItem("token");

  const location = useLocation();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    // Append form data fields
    formData.append("firstName", e.target.firstName.value);
    formData.append("lastName", e.target.lastName.value);
    formData.append("address", e.target.address.value);
    formData.append("city", e.target.city.value);
    formData.append("state", e.target.state.value);
    formData.append("zip", e.target.zip.value);
    formData.append("phone", e.target.phone.value);
    formData.append("additional_info", e.target.additional_info.value);

    // Append cart data
    formData.append("cartData", JSON.stringify(cartData)); // Convert cartData to JSON string

    // Append other necessary data
    formData.append("totalPrice", totalPrice);
    formData.append("totalDiscount", totalDiscount);
    formData.append("numberOfCartItems", numberOfCartItems);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API_URL}/api/orders`,
        {
          method: "POST",
          body: formData, // Send FormData directly
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to add order");
      }

      const data = await response.json();

      // Initialize Razorpay payment
      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY_ID,
        amount: data.amount, // Amount from backend (in paise)
        currency: data.currency, // Currency code
        name: "The Royal Man Ethnic Wardrobe", // Your store name
        description: "Order Payment", // Description of the transaction
        order_id: data.razorpay_order_id, // The order ID created on Razorpay
        handler: async (response) => {
          const verificationResponse = await fetch(
            `${process.env.REACT_APP_BACKEND_API_URL}/api/orders/verify-payment`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify({
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_order_id: response.razorpay_order_id,
                razorpay_signature: response.razorpay_signature,
                orderId: data.orderId, // Pass the database order ID for reference
              }),
            }
          );

          if (!verificationResponse.ok) {
            alert("Payment verification failed. Please contact support team!");
          }

          const verificationData = await verificationResponse.json();

          navigate("/order-confirmed", { state: { verificationData }, });

        },
        prefill: {
          name: `${data.firstName} ${data.lastName}`,
          contact: data.phone,
        },
        notes: {
          orderId: data.orderId, // Pass the database order ID for reference
        },
        // theme: {
        //   color: "#F37254", // Customize the color of the Razorpay checkout window
        // },
      };

      const rzp = new window.Razorpay(options);
      rzp.open();
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  return (
    <div className="p-5 mt-10">
      <h1 className="text-[22px] font-semibold mb-5">Place Order</h1>
      <form onSubmit={handleSubmit}>
        <div
          spacing={4}
          className="md:flex items-start justify-between w-full gap-[2rem]"
        >
          <div className="w-full md:w-1/2">
            <Box className="p-5 border shadow-md rounded-s-md">
              <Grid container spacing={3}>
                <Grid xs={12} sm={6} item>
                  <TextField
                    required
                    id="firstName"
                    name="firstName"
                    label="First Name"
                    fullWidth
                    autoComplete="given"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#EDB92B", // Change this to the color you want
                        },
                      },
                      "& .MuiInputLabel-root.Mui-focused": {
                        color: "#EDB92B", // Change label color when focused
                      },
                    }}
                  ></TextField>
                </Grid>

                <Grid xs={12} sm={6} item>
                  <TextField
                    required
                    id="lastName"
                    name="lastName"
                    label="Last Name"
                    fullWidth
                    autoComplete="given"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#EDB92B", // Change this to the color you want
                        },
                      },
                      "& .MuiInputLabel-root.Mui-focused": {
                        color: "#EDB92B", // Change label color when focused
                      },
                    }}
                  ></TextField>
                </Grid>

                <Grid xs={12} sm={12} item>
                  <TextField
                    required
                    id="address"
                    name="address"
                    label="Address"
                    fullWidth
                    autoComplete="given"
                    rows={4}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#EDB92B", // Change this to the color you want
                        },
                      },
                      "& .MuiInputLabel-root.Mui-focused": {
                        color: "#EDB92B", // Change label color when focused
                      },
                    }}
                  ></TextField>
                </Grid>

                <Grid xs={12} sm={6} item>
                  <TextField
                    required
                    id="city"
                    name="city"
                    label="City"
                    fullWidth
                    autoComplete="given"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#EDB92B", // Change this to the color you want
                        },
                      },
                      "& .MuiInputLabel-root.Mui-focused": {
                        color: "#EDB92B", // Change label color when focused
                      },
                    }}
                  ></TextField>
                </Grid>

                <Grid xs={12} sm={6} item>
                  <TextField
                    required
                    id="state"
                    name="state"
                    label="State/Region"
                    fullWidth
                    autoComplete="given"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#EDB92B", // Change this to the color you want
                        },
                      },
                      "& .MuiInputLabel-root.Mui-focused": {
                        color: "#EDB92B", // Change label color when focused
                      },
                    }}
                  ></TextField>
                </Grid>

                <Grid xs={12} sm={6} item>
                  <TextField
                    required
                    id="zip"
                    name="zip"
                    label="Zip/Postal code"
                    fullWidth
                    autoComplete="given"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#EDB92B", // Change this to the color you want
                        },
                      },
                      "& .MuiInputLabel-root.Mui-focused": {
                        color: "#EDB92B", // Change label color when focused
                      },
                    }}
                  ></TextField>
                </Grid>

                <Grid xs={12} sm={6} item>
                  <TextField
                    required
                    id="phone"
                    name="phone"
                    label="Phone Number"
                    fullWidth
                    autoComplete="given"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#EDB92B", // Change this to the color you want
                        },
                      },
                      "& .MuiInputLabel-root.Mui-focused": {
                        color: "#EDB92B", // Change label color when focused
                      },
                    }}
                  ></TextField>
                </Grid>
              </Grid>

              <div>
                <h1 className="mt-5 text-[24px] font-semibold mb-5">
                  Additional Information
                </h1>
                <TextField
                  name="additional_info"
                  id="additional_info"
                  label="Other notes (optional)"
                  fullWidth
                  autoComplete="given"
                  multiline
                  rows={5}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#EDB92B", // Change this to the color you want
                      },
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#EDB92B", // Change label color when focused
                    },
                  }}
                ></TextField>
              </div>
            </Box>
          </div>

          <div className="w-full md:w-1/2">
            <div className="top-0">
              <div className="p-4 p-5 border shadow-lg">
                <p className="pb-4 font-bold text-black opacity-60">
                  PRICE DETAILS
                </p>

                <hr />

                <div className="flex items-center justify-between mt-4 font-semibold">
                  <p>Price ({numberOfCartItems} items)</p>
                  <p>₹{totalPrice}</p>
                </div>

                <div className="flex items-center justify-between mt-2 font-semibold">
                  <p>Discount</p>
                  <p className="text-green-600 opacity-80">-₹{totalDiscount}</p>
                </div>

                <div className="flex items-center justify-between mt-2 mb-4 font-semibold">
                  <p>Delivery Charge</p>
                  <p className="text-green-600 opacity-80">Free</p>
                </div>

                <hr />

                <div className="flex items-center justify-between">
                  <p className="mt-4 font-bold text-black">Total Amount</p>
                  <p className="font-semibold text-green-600">
                    ₹{totalPrice - totalDiscount}
                  </p>
                </div>

                {/* Payment Button */}
                <button
                  type="submit"
                  className="w-full mt-8 h-[2.5rem] rounded-sm text-white font-semibold text-[22px] bg-[#E85826] hover:bg-[#A5320C]"
                >
                  Payment
                </button>
              </div>
            </div>

            {/* <div className="mt-5">
              <img src={razorPay} alt="razzor-pay" />
            </div> */}
          </div>
        </div>
      </form>
    </div>
  );
};

export default DeliveryAddressForm;
