import React from "react";
import { useNavigate } from "react-router-dom";

const AddToCart = ({ inCart, handleAddCartItem }) => {
  const navigate = useNavigate();

  const handleGoToCart = () => {
    navigate("/cart");
  }

  return (
    <div className="w-[10rem] h-[4rem] bg-[#E85826] hover:bg-[#A5320C] text-center rounded-sm flex items-center justify-center my-auto">
      {inCart ? (
        <button
          type="button"
          onClick={handleGoToCart}
          className="my-auto font-semibold tracking-wide text-white text[24px]"
        >
          GO TO CART
        </button>
      ) : (
        <button
          type="button"
          onClick={handleAddCartItem}
          className="my-auto font-semibold tracking-wide text-white text[24px]"
        >
          ADD TO CART
        </button>
      )}
    </div>
  );
};

export default AddToCart;
