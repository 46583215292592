import React, { useState } from "react";
import logo from "../../../Assets/images/logo.png";
import "./AdminLoginPage.css";

const AdminLoginPage = () => {
  const [PopUpMessage, setPopUpMessage] = useState("");
  const [showPopUp, setShowPopUp] = useState(false);

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const email = formData.get("email");
    const password = formData.get("password");

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API_URL}/api/admin/login`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, password }),
        }
      );
      const data = await response.json();

      if (!data.success) {
        setPopUpMessage(
          <p className="text-red-500 text-[18px]">
            Invalid Credentials. Please try again.{" "}
          </p>
        );
        setShowPopUp(true);
        setTimeout(() => {
          setShowPopUp(false);
          setTimeout(() => setPopUpMessage(""), 2000);
        }, 2000);
        return;
      }

      localStorage.setItem("adminToken", data.token);

      setPopUpMessage(
        <p className="text-green-400 text-[18px]">
          ✅ You have successfully logged in..
        </p>
      );
      setShowPopUp(true);
      setTimeout(() => {
        setShowPopUp(false);
        setTimeout(() => setPopUpMessage(""), 2000);
        window.location.href = "/admin/dashboard";
      }, 2000);
    } catch (error) {
      console.error("Error logging in:", error);
      alert("Error logging in. Please try again later.");
    }
  };

  return (
    <div className="relative flex flex-col items-center justify-center min-h-screen bg-[#]">
      {PopUpMessage && (
        <div
          className={`absolute top-[4rem] alert ${
            showPopUp ? "opacity-100" : "opacity-0"
          } transition-opacity duration-300`}
        >
          <div className="w-full px-4 py-2 bg-white rounded-lg shadow-lg">
            {PopUpMessage}
          </div>
        </div>
      )}
      <div className="lg:mt-[-8rem] mb-10 w-[10rem] ">
        <img src={logo} alt="logo" className="" />
      </div>

      <div className="my-4">
        <h1 className="mb-10 text-xl font-normal">
          Login Securely As Admin
        </h1>
      </div>

      <div>
        <form
          className="flex flex-col w-[20rem] gap-4"
          action=""
          onSubmit={handleLoginSubmit}
        >
          <label htmlFor="email" className="text-sm">
            Email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Enter your email"
            className="p-2 border border-gray-300 rounded"
            required
          />

          <label htmlFor="password" className="text-sm">
            Password
          </label>
          <input
            type="password"
            id="password"
            name="password"
            placeholder="Enter your password"
            className="p-2 border border-gray-300 rounded"
            required
          />

          <button
            type="submit"
            className="py-2 mt-4 text-white bg-blue-500 rounded hover:bg-blue-600"
          >
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default AdminLoginPage;
