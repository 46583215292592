import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import SEO from "../../SEO/seo";
import MyProfile from "../../Components/MyProfile/MyProfile";

const AccountPage = () => {
  const [user, setUser] = useState(null);
  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_API_URL}/api/user/account`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch user details");
        }

        const data = await response.json();
        setUser(data.user);
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    fetchUserDetails();
  }, [token]);

  useEffect(() => {
    if (!token) {
      window.location.href = "/";
    }
  }, []);

  if (!user) {
    return (
      <div>
        <Navbar />
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <p>Loading user details...</p>
        </div>
        <Footer />
      </div>
    );
  }

  return (
    <div>
      <SEO 
  title="My Account | The Royal Man Ethnic Wardrobe" 
  description="Manage your account details, view your orders, and update your preferences at HAIDER Ethnics. Sign in to access exclusive offers and track your purchases." 
/>

      <Navbar />
      <MyProfile user={user} />
      <Footer />
    </div>
  );
};

export default AccountPage;
