import React from "react";

const ContactUs = () => {
  return (
    <div className=" items-center justify-center lg:px-[5rem] py-[2rem] px-[1rem]">
      <div className="p-5 rounded-lg shadow-lg bg-[#F9EDD7]">
        <p className="font-semibold text-[18px] tracking-wider text-[#A5320C] text-center">
          ADDRESS
        </p>
        <p className="mt-6 text-center">
          Tilak Road, Gandhi gate, shivaji putla, Mahal, Nagpur, Maharashtra, 440002, INDIA
        </p>
        <div className="w-[10rem] h-[3rem] bg-[#E85826] hover:bg-[#A5320C] text-center rounded-lg flex items-center justify-center mx-auto mt-8">
          <button className="w-full mx-auto font-semibold tracking-wide text-white text-[20px]" >
            <a href="https://maps.app.goo.gl/jiBeofQEYXRKgWxR6" target='_blank'>GOOGLE MAP</a>
          </button>
        </div>

        <p className="font-semibold text-[18px] tracking-wider text-[#A5320C] text-center mt-10 mb-5">
          TIME
        </p>
        <p className="text-center">Time: 11AM - 10PM (Monday - Sunday)</p>
        <p className="text-center">03PM - 10PM (Friday)</p>
        <p className="text-center"></p>

        <p className="font-semibold text-[18px] tracking-wider text-[#A5320C] text-center mt-10 mb-5">
          CONTACTS
        </p>
        <p className="text-center">+91 9325656679</p>
        <p className="text-center cursor-pointer">
         <a href="mailto:theroyalmanethnic@gmail.com" target="_blank" className="underline">theroyalmanethnic@gmail.com</a>
        </p>
      </div>
    </div>
  );
};

export default ContactUs;
