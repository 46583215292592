import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Categories from "../../Components/Categories/Categories";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import SEO from "../../SEO/seo";

const CategoryPage = () => {
  const { categoryName } = useParams();
  const [categoryProducts, setCategoryProducts] = useState([]);

  useEffect(() => {
    const fetchCategoryProducts = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_API_URL}/api/product/category-products?category=${categoryName}`
        );
        const data = await response.json();

        setCategoryProducts(data.data);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchCategoryProducts();

    window.scrollTo(0, 0);
  }, [categoryName]);

  return (
    <div>
      <SEO
        title="Sherwanis Collection | The Royal Man Ethnic Wardrobe"
        description="Explore our stunning collection of Sherwanis at HAIDER Ethnics. Perfect for weddings, festivals, and special occasions, our range features traditional and modern designs crafted with the finest fabrics. Shop now for the perfect ethnic wear!"
      />

      <Navbar />
      <Categories
        categoryName={categoryName}
        categoryProduct={categoryProducts}
      />
      <Footer />
    </div>
  );
};

export default CategoryPage;
