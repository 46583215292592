import React from "react";
import { MdDelete } from "react-icons/md";

const CateogryImageCard = ({category, handleDeleteCategory}) => {
  
  return (
    <div className="bg-[#FEFAF2] shadow-lg rounded-md relative">
      <img
        className="w-full"
        src={category.category_imageurl}
        alt="category-representation"
      />
      <MdDelete size={22} className="absolute cursor-pointer top-2 right-2 text-[#A5320C]" onClick={()=>handleDeleteCategory(category.category_id)}/>
      <div className="flex items-center justify-between w-full">
        <p className="text-center text-[20px] font-semibold my-2 text-[#A5320C] flex-grow">
          {category.category_name}
        </p>
      </div>
    </div>
  );
};

export default CateogryImageCard;
