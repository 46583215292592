// WishlistItem.js
import React from "react";
import { useNavigate } from "react-router-dom";
import { MdDelete } from "react-icons/md";

const WishlistItem = ({ wishlistItem, handleDelete }) => {
  const navigate = useNavigate();

  // Function to navigate to product details
  const handleNavigate = (product_id) => {
    navigate(`/products/${product_id}`);
  };

  return (
    <div className="lg:p-5 p-2 my-2 lg:m-5 border rounded-md shadow-sm bg-[#FFFBF2]">
      <div>
        <div className="flex items-center space-x-4">
          <div className="w-[5rem] h-[5rem] lg:w-[9rem] lg:h-[9rem]">
            <img
              className="object-cover object-top w-full h-full cursor-pointer"
              src={wishlistItem.main_image}
              alt=""
              onClick={() => handleNavigate(wishlistItem.product_id)}
            />
          </div>

          <div className="flex items-start cursor-pointer lg:gap-5">
            <div
              className="ml-5 space-y-1"
              onClick={() => handleNavigate(wishlistItem.product_id)}
            >
              <p className="font-semibold w-[200px] lg:w-[300px] whitespace-nowrap overflow-hidden text-ellipsis">
                {wishlistItem.product_name}
              </p>
              {/* <p className="opacity-70">Size: M,White</p>
              <p className="mt-2 opacity-70">Seller: FOLGEN</p> */}

              <div
                className="flex items-center space-x-2 text-gray-900"
                style={{ marginTop: "16px" }}
              >
                <p className="font-semibold">₹{wishlistItem.actual_price}</p>
                <p className="line-through opacity-50">₹{wishlistItem.product_price}</p>
                <p className="font-semibold text-green-600">{wishlistItem.discount}% Off</p>
              </div>
            </div>

            <div>
              <MdDelete
                size={22}
                className="ml-10"
                style={{ color: "#A5320C", cursor: "pointer" }}
                onClick={() => handleDelete(wishlistItem.wishlist_id)} 
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WishlistItem;
