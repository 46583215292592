import React from "react";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import SEO from "../../SEO/seo";
import HowToOrder from "../../Components/HowToOrder/HowToOrder";

const HowToOrderPage = () => {
  return (
    <div>
      <SEO
        title="How to Order | The Royal Man Ethnic Wardrobe"
        description="Learn how to shop easily at HAIDER Ethnics. Our guide walks you through the steps to browse products, add to cart, and complete your purchase seamlessly."
      />

      <Navbar />
      <HowToOrder />
      <Footer />
    </div>
  );
};

export default HowToOrderPage;
