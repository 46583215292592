import React, { useEffect, useState } from "react";

const OrderDetails = ({ allOrder }) => {
  const token = localStorage.getItem("adminToken");
  const [user, setUser] = useState({});
  const [orderItems, setOrderItems] = useState([]);
  const [productDetails, setProductDetails] = useState({});
  const [orderStatus, setOrderStatus] = useState({});

  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_API_URL}/api/admin/user/${allOrder.user_id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        setUser(data.user);
      } catch (error) {
        console.error(error.message);
      }
    };

    getUser();
  }, [allOrder.user_id]);

  useEffect(() => {
    const getOrderItems = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_API_URL}/api/admin/order/${allOrder.order_id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        setOrderItems(data.orderItems);

        // Initialize order status for each item
        const initialOrderStatus = data.orderItems.reduce((acc, item) => {
          acc[item.order_item_id] = item.order_status; // Map each item's id to its status
          return acc;
        }, {});
        setOrderStatus(initialOrderStatus); // Set the initial statuses

        // Fetch product details for each order item
        const fetchProductDetails = async () => {
          const productDetailPromises = data.orderItems.map(
            async (orderItem) => {
              const productResponse = await fetch(
                `${process.env.REACT_APP_BACKEND_API_URL}/api/admin/product/${orderItem.product_id}`,
                {
                  method: "GET",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                  },
                }
              );
              const productData = await productResponse.json();
              return {
                productId: orderItem.product_id,
                ...productData.product,
              };
            }
          );

          // Wait for all product detail fetches to complete
          const productDetailsArray = await Promise.all(productDetailPromises);
          // Create an object keyed by product_id for easy access
          const productDetailsObj = productDetailsArray.reduce(
            (acc, product) => {
              acc[product.productId] = product;
              return acc;
            },
            {}
          );

          setProductDetails(productDetailsObj);
        };

        fetchProductDetails();
      } catch (error) {
        console.error(error.message);
      }
    };

    getOrderItems();
  }, [allOrder.order_id, token]);

  const handleUpdateStatus = async (order_item_id, newStatus) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API_URL}/api/admin/order/update-status/${order_item_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ status: newStatus }),
        }
      );
      const responseData = await response.json();
      if (responseData.success) {
        setOrderStatus((prevStatus) => ({
          ...prevStatus,
          [order_item_id]: newStatus, // Update the specific item's status
        }));
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <div className="w-full p-6 mx-auto mb-10 space-y-6 border rounded-md shadow-md">
      {/* User and Order Information */}
      <div className="space-y-3 text-[16px] w-full">
        <div className="flex flex-wrap justify-between ">
          <p>
            <span className="font-semibold">Name:</span> {user.first_name}{" "}
            {user.last_name}
          </p>
          <p>
            <span className="font-semibold">Email:</span> {user.email_id}
          </p>
        </div>
        <div className="flex flex-wrap justify-between">
          <p>
            <span className="font-semibold">Mobile No:</span> {user.phone_no}
          </p>
          <p>
            <span className="font-semibold">Total Amount Paid:</span> ₹
            {allOrder.total_amount}
          </p>
        </div>
        <div>
          <p>
            <span className="font-semibold">Shipping Address:</span>{" "}
            {allOrder.shipping_address}
          </p>
        </div>
        <div className="flex flex-wrap justify-between">
          <p>
            <span className="font-semibold">Ordered On:</span>{" "}
            {new Date(allOrder.created_at).toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "short",
              year: "numeric",
            })}
          </p>

          <p>
            <span className="font-semibold">Payment Id:</span>{" "}
            {allOrder.payment_id}
          </p>
        </div>
        <div>
          <p>
            <span className="font-semibold">Additional Info:</span>{" "}
            {allOrder.additional_info}
          </p>
        </div>
        <div className="text-sm text-right text-gray-500">
          order id: {allOrder.order_id}
        </div>

        {/* Order Items */}
        {orderItems.map((orderItem) => {
          const productDetail = productDetails[orderItem.product_id] || {};
          return (
            <div className="w-full space-y-4" key={orderItem.order_item_id}>
              {/* Order Item Card */}
              <div className="p-5 border rounded-md shadow-sm bg-[#FFFBF2]">
                <div className="flex items-start w-full space-x-4">
                  <div className="w-[5rem] h-[5rem] lg:w-[9rem] lg:h-[9rem]">
                    <img
                      className="object-cover object-top w-full h-full"
                      src={productDetail.main_image || "fallback_image_url"}
                      alt="Product"
                    />
                  </div>

                  <div className="w-full ml-5 space-y-1">
                    <p className="font-semibold">
                      {productDetail.product_name || "Product Name"}
                    </p>
                    <div className="flex items-center gap-10">
                      <p className="text-gray-600">Size: {orderItem.size}</p>
                      <p className="text-gray-600">
                        Quantity: {orderItem.quantity}
                      </p>
                    </div>

                    <p className="text-gray-600">Price: ₹{orderItem.price}</p>

                    <div className="items-center justify-between mt-4 space-x-2 text-gray-900 sm:flex">
                      <p className="font-semibold text-green-600 text-[16px] capitalize">
                        Order Status: {orderStatus[orderItem.order_item_id]}
                      </p>
                      <div className="items-center justify-center hidden gap-5 sm:flex ">
                        <p>Update Status: </p>
                        <button
                          className="font-semibold tracking-wide text-white text[24px] w-[6rem] h-[3rem] bg-[#6dcbeb] text-center rounded-sm flex items-center justify-center my-auto"
                          onClick={() =>
                            handleUpdateStatus(
                              orderItem.order_item_id,
                              "Shipped"
                            )
                          }
                        >
                          Shipped
                        </button>
                        <button
                          className="font-semibold tracking-wide text-white text[24px] w-[6rem] h-[3rem] bg-[#50b950] text-center rounded-sm flex items-center justify-center my-auto"
                          onClick={() =>
                            handleUpdateStatus(orderItem.order_item_id, "Delivered")
                          }
                        >
                          Delivered
                        </button>
                      </div>
                    </div>
                    <div className="block sm:hidden">
                      <p className="text-[16px]">Update Status: </p>
                      <div className="flex items-center gap-2">
                        <button
                          className="font-normal text-white text[24px] w-[4rem] h-[2rem] bg-[#6dcbeb] text-center rounded-sm flex items-center justify-center my-auto"
                          onClick={() =>
                            handleUpdateStatus({
                              status: "shipped",
                              order_items_id: orderItem.order_item_id,
                            })
                          }
                        >
                          Shipped
                        </button>
                        <button
                          className="font-normal tracking-wide text-white text[24px] w-[5rem] h-[2rem] bg-[#50b950] text-center rounded-sm flex items-center justify-center my-auto"
                          onClick={() =>
                            handleUpdateStatus({
                              status: "delivered",
                              order_items_id: orderItem.order_item_id,
                            })
                          }
                        >
                          Delivered
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default OrderDetails;
