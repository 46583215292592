import React from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { MainCarouselData } from "./MainCarouselData";

const MainCarousel = () => {
  let items = MainCarouselData.map((item, index) =>
    item.href ? (
      <a href={item.href} target="_blank" rel="noopener noreferrer" key={index}>
        <img
          src={item.image}
          className="z-0 cursor-pointer"
          role="presentation"
          alt=""
        />
      </a>
    ) : (
      <img
        src={item.image}
        className="z-0 cursor-pointer"
        role="presentation"
        alt=""
        key={index}
      />
    )
  );

  return (
    <AliceCarousel
      items={items}
      mouseTracking
      autoPlayInterval={5000}
      infinite={true}
      autoPlayDirection="rtl"
      autoPlay={true}
      disableButtonsControls={true}
      disableDotsControls={true}
    />
  );
};

export default MainCarousel;
