import React from "react";

const UserCard = ({ user }) => {
  return (
    <div className="w-full mx-auto overflow-hidden rounded-lg shadow-lg">
      <div className="px-6 py-4">
        <h2 className="text-xl font-semibold text-gray-800">
          {user.first_name} {user.last_name}
        </h2>
        <p className="text-gray-600">
          <strong>Email:</strong> {user.email_id}
        </p>
        <p className="text-gray-600">
          <strong>Phone:</strong> {user.phone_no}
        </p>
        <div className="mt-4">
          <p className="text-gray-600">
            <strong>Address:</strong>
          </p>
          {user.address ? (
            <>
              <p className="text-gray-600">{user.address.address}</p>
              <p className="text-gray-600">
                {user.address.city} {user.address.zip}
              </p>
              <p className="text-gray-600">{user.address.country}</p>
            </>
          ) : (
            <p className="text-gray-600">adrress: Not available</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserCard;
