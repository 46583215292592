import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './Pages/HomePage/HomePage';
import ProductPage from './Pages/ProductPage/ProductPage';
import ProductDetailsPage from './Pages/ProductDetailsPage/ProductDetailsPage';
import CategoryPage from './Pages/CategoryPage/CategoryPage';
import CartPage from './Pages/CartPage/CartPage';
import CheckoutPage from './Pages/CheckoutPage/CheckoutPage';
import HowToOrder from './Pages/HowToOrderPage/HowToOrderPage';
import Account from './Pages/AccountPage/AccountPage';
import Contact from './Pages/ContactPage/ContactPage';
import Wishlist from './Pages/WishlistPage/WishlistPage';
import Policies from './Pages/PoliciesPage/PoliciesPage';
import TrackOrder from './Pages/TrackOrderPage/TrackOrderPage';
import AdminLoginPage from './admin/Pages/AdminLoginPage/AdminLoginPage';
import AdminDashboard from './admin/Pages/AdminDashboard/AdminDashboard';
import OrderConfirmed from './Pages/OrderConfirmedPage/OrderConfirmedPage';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/products" element={<ProductPage />} />
          <Route path="/products/:productId" element={<ProductDetailsPage />} />
          <Route path="/category/:categoryName" element={<CategoryPage />} />
          <Route path="/cart" element={<CartPage />} />
          <Route path="/checkout" element={<CheckoutPage />} />
          <Route path="/account" element={<Account />} />
          <Route path="/wishlist" element={<Wishlist />} />
          <Route path="/track-order" element={<TrackOrder />} />
          <Route path="/policies" element={<Policies />} />
          <Route path="/order-confirmed" element={<OrderConfirmed />} />

          {/* Static Pages */}
          <Route path="/how-to-order" element={<HowToOrder />} />
          <Route path="/contact" element={<Contact />} />
          

          {/* Admin Routes */}
          <Route path="/admin/login" element={<AdminLoginPage />} /> 
          <Route path="/admin/dashboard" element={<AdminDashboard />} /> 


          {/* 404 Page Not Found */}
          <Route path="*" element={<div className='font-bold flex flex-col items-center justify-center text-[24px] text-center'>404 - Page Not Found <br /> Please comeback to homepage</div>} />


          
        </Routes>
      </Router>
    </div>
  );
}

export default App;

