import React from "react";
import assuredquality from "../../Assets/images/assured-quality.png";
import securepayments from "../../Assets/images/secure-payments.png";
import customfitting from "../../Assets/images/custom-fitting.png";
import allIndiaDelivery from "../../Assets/images/all-india-delivery.png";


const Features = () => {
  return (
    <>
    <hr className="h-[2px] bg-gray-600 opacity-10"/>
    <div className="grid items-center justify-center grid-cols-1 sm:grid-cols-4 pb-[3rem]">
      <div className="h-[8rem]">
        <img src={assuredquality} alt="representation" />
      </div>

      <div className="h-[8rem]">
        <img src={securepayments} alt="representation" />
      </div>

      <div className="h-[8rem]">
        <img src={customfitting} alt="representation" />
      </div>

      <div className="h-[8rem]">
        <img src={allIndiaDelivery} alt="representation" />
      </div>
    </div>
    </>
  );
};


export default Features;
