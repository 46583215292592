import React, { useEffect } from "react";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import Wishlist from "../../Components/Wishlist/Wishlist";
import SEO from "../../SEO/seo";

const WishlistPage = () => {
  const token = localStorage.getItem("token");
  useEffect(() => {
    if (!token) {
      window.location.href = "/";
    }
  }, []);
  return (
    <div>
      <SEO
        title="Wishlist | The Royal Man Ethnic Wardrobe"
        description="View and manage your saved items at HAIDER Ethnics. Create your wishlist of favorite products and easily access them when you're ready to shop!"
      />

      <Navbar />
      <Wishlist />
      <Footer />
    </div>
  );
};

export default WishlistPage;
