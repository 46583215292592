import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import ContactUs from "../../Components/ContactUs/ContactUs";
import SEO from "../../SEO/seo";

const ContactPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <SEO
        title="Contact Us | The Royal Man Ethnic Wardrobe"
        description="Have questions? Reach out to HAIDER Ethnics for customer support, inquiries, or feedback. We're here to assist you with your ethnic wear needs."
      />

      <Navbar />
      <ContactUs />
      <Footer />
    </div>
  );
};

export default ContactPage;
