import React from "react";
import { Link } from "react-router-dom";
import colors from "../../styles/color";
import logo from "../../Assets/images/logo.png";
import { MdOutlineMailOutline } from "react-icons/md";
import { TfiTwitterAlt } from "react-icons/tfi";
import { FaFacebookF } from "react-icons/fa";
import { LuPhone } from "react-icons/lu";
// import { FaLinkedin } from "react-icons/fa";
import { PiInstagramLogoFill } from "react-icons/pi";
import { FaYoutube } from "react-icons/fa";
// import { FaPinterest } from "react-icons/fa";

const Footer = () => {
  // const token = localStorage.getItem("token");
  return (
    <div className="" style={{ backgroundColor: colors.footerBg }}>
      <div className="sm:px-[5rem] py-[2rem]">
        <div className="flex items-center justify-between p-4">
          <div className="sm:w-[10rem] w-[8rem]">
            <img src={logo} alt="logo" />
          </div>

          <div className="mt-4 text-center">
            <h1 className="text-white text-[20px] font-normal">
              KEEP IN TOUCH
            </h1>

            {/* Responsive Social Media Icons */}
            <div
              className="grid grid-cols-3 sm:grid-cols-6 sm:gap-[2rem] gap-[1rem] justify-center mt-6 cursor-pointer"
              style={{ color: colors.socialMediaIcon }}
            >
              <a
                href="mailto:theroyalmanethnic@gmail.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <MdOutlineMailOutline size={22} />
              </a>
              <a
                href="https://x.com/TheroyalmanEW?t=Lx5tJjq2a5ENsPoW-l4kpg&s=09"
                target="_blank"
                rel="noopener noreferrer"
              >
                <TfiTwitterAlt size={22} />
              </a>
              <a
                href="https://www.facebook.com/profile.php?id=100071908942294&mibextid=LQQJ4d"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebookF size={22} />
              </a>
              <a
                href="tel:+919325656679"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LuPhone size={22} />
              </a>
              <a
                href="https://www.instagram.com/the_royal_man_ethnic_wardrobe/profilecard/?igsh=YW54dTQzZTRvNXM4"
                target="_blank"
                rel="noopener noreferrer"
              >
                <PiInstagramLogoFill size={22} />
              </a>
              <a
                href="https://www.youtube.com/@TheRoyalManEthnicwardrobe"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaYoutube size={22} />
              </a>
            </div>
          </div>
        </div>

        <hr className="h-[2px] mt-[2rem] mb-[1.5rem] bg-[#501D01] opacity-15" />

        <div className="grid grid-cols-2 sm:grid-cols-4 gap-[2rem] p-5 sm:gap-[4rem] text-white">
          <div className="space-y-4">
            <h1 className="font-semibold tracking-wide">CATEGORIES</h1>
            <ul className="space-y-2 font-normal">
              <li>
                <Link to="/category/sherwani" className="hover:underline">
                  Sherwani
                </Link>
              </li>
              <li>
                <Link to="/category/indowestern" className="hover:underline">
                  Indowestern
                </Link>
              </li>
              <li>
                <Link to="/category/jodhpuri" className="hover:underline">
                  Jodhpuri
                </Link>
              </li>
              <li>
                <Link to="/category/koti-kurta" className="hover:underline">
                  Koti Kurta
                </Link>
              </li>
              <li>
                <Link to="/category/tuxedo" className="hover:underline">
                  Tuxedo
                </Link>
              </li>
              <li>
                <Link to="/category/suit" className="hover:underline">
                  Suit
                </Link>
              </li>
              <li>
                <Link to="/category/kurta" className="hover:underline">
                  Kurta
                </Link>
              </li>
              <li>
                <Link to="/category/formals" className="hover:underline">
                  Formals
                </Link>
              </li>
            </ul>
          </div>

          <div className="space-y-4">
            <h1 className="font-semibold tracking-wide">SUPPORT</h1>
            <ul className="space-y-2">
              <li>
                <Link to="/track-order" className="hover:underline">
                  Track Order
                </Link>
              </li>
              <li>
                <Link to="/contact" className="hover:underline">
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>

          <div className="space-y-4">
            <h1 className="font-semibold tracking-wide">OUR POLICIES</h1>
            <ul className="space-y-2">
              <li>
                <a
                  href="/policies#shipping-details"
                  className="hover:underline"
                >
                  Shipping Details
                </a>
              </li>
              <li>
                <a href="/policies#return-policy" className="hover:underline">
                  Return, Exchange and Refund Policy
                </a>
              </li>
              <li>
                <a href="/policies#terms-of-use" className="hover:underline">
                  Terms of Use
                </a>
              </li>
              <li>
                <a href="/policies#privacy-policy" className="hover:underline">
                  Privacy Policy
                </a>
              </li>
            </ul>
          </div>

          <div className="space-y-4">
            <h1 className="font-semibold tracking-wide">CONTACT</h1>
            <ul className="space-y-2">
              <li>Call us at: +91 9325656679</li>
              <li>Time: 11AM - 10PM (Mon - Sun)</li>
              <li className="lg:ml-12">03PM - 10PM (Fri)</li>
              <li>
                <div className="w-full ">
                  <span>Shop Address:</span>
                  <span className="text-[14px]">
                    {" "}
                    Tilak Road, Gandhi gate, shivaji putla, Mahal, Nagpur, Maharashtra, 440002, INDIA
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <hr className="h-[2px] mt-[2rem] mb-[1.5rem] bg-[#501D01] opacity-15" />

        <div>
          <p className="text-white text-[14px] font-normal text-center mt-5">
            © 2024 The Royal Man Ethnic Wardrobe. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
