import React, { useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import DeliveryAddressForm from './DeliveryAddressForm';

const Checkout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { cartData, totalPrice, totalDiscount, numberOfCartItems } = location.state || {};

  

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  

  useEffect(() => {
    if (!location.state) {
      navigate('/');
    }
  }, [location.state, navigate]);

  return (
    <div>
      <DeliveryAddressForm cartData={cartData} totalPrice={totalPrice} totalDiscount={totalDiscount} numberOfCartItems={numberOfCartItems}/>
    </div>
  );
}

export default Checkout;
