import React, {useEffect} from 'react'
import SEO from "../../SEO/seo";

const TrackOrderPage = () => {

  const token = localStorage.getItem("token");

  useEffect(() => {
    if (!token) {
      window.location.href = "/";
    }
  }, []);

  return (
    <div>
      <SEO 
  title="Track Your Order | The Royal Man Ethnic Wardrobe" 
  description="Stay updated with your order status at HAIDER Ethnics. Enter your order ID to track shipping and delivery information for your recent purchases." 
/>
  
        {/* <Navbar /> */}
        Track Order
        {/* <TrackOrder /> */}
        {/* <Footer /> */}
    </div>
  )
}

export default TrackOrderPage
