import React, { useState, useEffect } from "react";
import { RiAccountCircleLine } from "react-icons/ri";
// import { IoIosHeartEmpty } from "react-icons/io";
import { FiBox } from "react-icons/fi";
import { FaRegAddressCard } from "react-icons/fa6";
import { MdOutlineHeadphones } from "react-icons/md";
import { Grid, TextField } from "@mui/material";
import { TbLogout2 } from "react-icons/tb";
import { useLocation, useNavigate } from "react-router-dom";
import whatsAppIcon from "../../Assets/images/whatsapp-icon.png";
import mailIcon from "../../Assets/images/mail-icon.png";
import telephoneIcon from "../../Assets/images/telephone-icon.png";
import telegramIcon from "../../Assets/images/telegram-icon.png";

import { logout } from "../../utils/authService";
const { Link } = require("react-router-dom");

const MyProfile = ({ user }) => {
  const token = localStorage.getItem("token");
  const [activeTab, setActiveTab] = useState("accountDetails");
  const Navigate = require("react-router-dom").Navigate;
  const navigate = useNavigate();
  useEffect(() => {
    if (!token) {
      window.location.href = "/";
    }
  }, []);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);

    if (tab === "myOrders") {
      handleMyOrders();
    }
  };

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  });

  const [addressData, setAddressData] = useState({
    address: "",
    city: "",
    state: "",
    zip: "",
    country: "",
  });

  useEffect(() => {
    if (user) {
      setFormData({
        firstName: user.first_name || "",
        lastName: user.last_name || "",
        email: user.email_id || "",
        phone: user.phone_no || "",
      });
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      setAddressData({
        address: user.address?.address
          ? user.address.address.charAt(0).toUpperCase() +
            user.address.address.slice(1)
          : "",
        city: user.address?.city
          ? user.address.city.charAt(0).toUpperCase() +
            user.address.city.slice(1)
          : "",
        state: user.address?.state
          ? user.address.state.charAt(0).toUpperCase() +
            user.address.state.slice(1)
          : "",
        zip: user.address?.zip || "",
        country: user.address?.country
          ? user.address.country.charAt(0).toUpperCase() +
            user.address.country.slice(1)
          : "",
      });
    }
  }, [user]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleAddressInputChange = (e) => {
    const { name, value } = e.target;
    setAddressData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDetailsFormSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API_URL}/api/user/update-account`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            first_name: formData.firstName,
            last_name: formData.lastName,
            phone_no: formData.phone,
          }),
        }
      );

      if (response.ok) {
        alert("Account details updated successfully!");
      } else {
        alert("Failed to update account details. Please try again.");
      }
    } catch (error) {
      console.error("Error updating account details:", error);
      alert("Server error. Please try again later.");
    }
  };

  const handleAddressFormSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API_URL}/api/user/update-address`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            address: addressData.address,
            city: addressData.city,
            state: addressData.state,
            zip: addressData.zip,
            country: addressData.country,
          }),
        }
      );

      // const data = await response.json();

      if (response.ok) {
        alert("Address updated successfully!");
      } else {
        alert("Failed to update address. Please try again.");
      }
    } catch (error) {
      console.error("Error updating address:", error);
      alert("Server error. Please try again later.");
    }
  };

  // Fetch user's orders

  const [orders, setOrders] = useState([]);

  const handleMyOrders = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API_URL}/api/user/orders`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch user orders");
      }

      const data = await response.json();
      setOrders(data);
    } catch (error) {
      console.error("Error fetching user orders:", error);
    }
  };

  const handleProductRedirect = (product_id) => {
    navigate(`/products/${product_id}`);
  };

  const handleLogout = () => {
    logout();
  };

  return (
    <>
      <h1 className="text-center text-[#A5320C] text-[24px] font-bold my-5 hidden lg:block">
        My Profile
      </h1>
      <div className="lg:flex items-start justify-start w-full grid-cols-1 p-[2rem] lg:grid-cols-2 border">
        <div className="lg:flex lg:w-[30%] w-full lg:gap-10">
          <div className="grid grid-cols-2 gap-2 lg:gap-4 lg:block">
            {/* Account Details */}
            <div
              onClick={() => handleTabClick("accountDetails")}
              className={`lg:border-none border lg:p-0 px-5 py-2 border-[#E85826] flex items-center lg:gap-6 gap-2 mb-[2rem] cursor-pointer ${
                activeTab === "accountDetails" ? " text-[#EDB92B]" : ""
              } hover:text-[#EDB92B]`}
            >
              <RiAccountCircleLine size={30} />
              <p className="text-[20px] hidden lg:block">Account Details</p>
              <p className="text-[20px] block lg:hidden">Account</p>
            </div>

            {/* My Orders */}
            <div
              onClick={() => handleTabClick("myOrders")}
              className={`lg:border-none border lg:p-0 px-5 py-2 border-[#E85826] flex items-center lg:gap-6 gap-2 mb-[2rem] cursor-pointer ${
                activeTab === "myOrders" ? "text-[#EDB92B]" : ""
              } hover:text-[#EDB92B]`}
            >
              <FiBox size={30} />
              <p className="text-[20px] hidden lg:block">My Orders</p>
              <p className="text-[20px] block lg:hidden">Orders</p>
            </div>

            {/* Addresses */}
            <div
              onClick={() => handleTabClick("addresses")}
              className={`lg:border-none border lg:p-0 px-5 py-2 border-[#E85826] flex items-center lg:gap-6 gap-2 mb-[2rem] cursor-pointer ${
                activeTab === "addresses" ? "text-[#EDB92B]" : ""
              } hover:text-[#EDB92B]`}
            >
              <FaRegAddressCard size={30} />
              <p className="text-[20px]">Address</p>
            </div>

            {/* Help Center */}
            <div
              onClick={() => handleTabClick("helpCenter")}
              className={`lg:border-none border lg:p-0 px-5 py-2 border-[#E85826] flex items-center lg:gap-6 gap-2 mb-[2rem] cursor-pointer ${
                activeTab === "helpCenter" ? "text-[#EDB92B]" : ""
              } hover:text-[#EDB92B]`}
            >
              <MdOutlineHeadphones size={30} />
              <p className="text-[20px] hidden lg:block">Help Center</p>
              <p className="text-[20px] block lg:hidden">Help</p>
            </div>

            {/* Logout */}
            <div
              onClick={handleLogout}
              className="hidden lg:flex items-center gap-6 mb-[2rem] cursor-pointer hover:text-[#EDB92B]"
            >
              <TbLogout2 size={30} />
              <p className="text-[20px]">Logout</p>
            </div>
          </div>

          <div className="hidden lg:block border-[1px] border-gray-300 opacity-40 shadow-lg h-100"></div>
        </div>

        {/* // Account Details */}
        {activeTab === "accountDetails" && (
          <div className="lg:w-[80%] w-full lg:mt-0 mt-10">
            <form onSubmit={handleDetailsFormSubmit}>
              <Grid container spacing={3}>
                <Grid xs={12} sm={6} item>
                  <TextField
                    required
                    id="firstName"
                    name="firstName"
                    label="First Name"
                    fullWidth
                    value={formData.firstName} // Prefill the input
                    onChange={handleInputChange} // Handle changes
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#EDB92B",
                        },
                      },
                      "& .MuiInputLabel-root.Mui-focused": {
                        color: "#EDB92B",
                      },
                    }}
                  />
                </Grid>

                <Grid xs={12} sm={6} item>
                  <TextField
                    required
                    id="lastName"
                    name="lastName"
                    label="Last Name"
                    fullWidth
                    value={formData.lastName}
                    onChange={handleInputChange}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#EDB92B",
                        },
                      },
                      "& .MuiInputLabel-root.Mui-focused": {
                        color: "#EDB92B",
                      },
                    }}
                  />
                </Grid>

                <Grid xs={12} sm={6} item>
                  <TextField
                    required
                    id="email"
                    name="email"
                    label="Email"
                    disabled
                    fullWidth
                    value={formData.email}
                    onChange={handleInputChange}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#EDB92B",
                        },
                      },
                      "& .MuiInputLabel-root.Mui-focused": {
                        color: "#EDB92B",
                      },
                    }}
                  />
                </Grid>

                <Grid xs={12} sm={6} item>
                  <TextField
                    required
                    id="phone"
                    name="phone"
                    label="Phone Number"
                    fullWidth
                    value={formData.phone}
                    onChange={handleInputChange}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#EDB92B",
                        },
                      },
                      "& .MuiInputLabel-root.Mui-focused": {
                        color: "#EDB92B",
                      },
                    }}
                  />
                </Grid>
              </Grid>

              <div className="flex items-center justify-center mt-10">
                <button
                  className="w-[10rem] h-[3rem] bg-[#E85826] hover:bg-[#A5320C] rounded-lg text-white font-[22px] text-center font-semibold"
                  type="submit"
                >
                  <span>Save Changes</span>
                </button>
              </div>
            </form>
          </div>
        )}

        {/* My Orders */}
        {activeTab === "myOrders" && (
          <div className="lg:w-[80%] w-full lg-mt-0 mt-5">
            {orders.length === 0 ? (
              <div
                style={{ textAlign: "center", padding: "50px" }}
                className=""
              >
                <h2 className="mb-2 font-bold text-md">
                  Your orders list is empty.
                </h2>
                <p className="text-[18px] font-normal">
                  It looks like you haven’t placed any orders yet. Start
                  shopping and place your first order!
                </p>
                <Link to="/products">
                  <div className="w-[15rem] h-[4rem] bg-[#E85826] hover:bg-[#A5320C] text-center mx-auto mt-10 rounded-sm flex items-center justify-center my-auto">
                    <button
                      type="button"
                      className="my-auto font-semibold tracking-wide text-white text[24px]"
                    >
                      CONTINUE SHOPPING
                    </button>
                  </div>
                </Link>
              </div>
            ) : (
              orders
                .sort((a, b) => new Date(b.created_at) - new Date(a.created_at)) // Sort by created_at, most recent first
                .map((order, index) => (
                  <div
                    key={index}
                    className="p-5 lg:m-5 border rounded-md shadow-sm bg-[#FFFBF2]"
                  >
                    <div className="flex items-start space-x-4">
                      <div className="w-[5rem] h-[5rem] lg:w-[9rem] lg:h-[9rem]">
                        <img
                          className="object-cover object-top w-full h-full cursor-pointer"
                          onClick={() =>
                            handleProductRedirect(order.product_id)
                          }
                          src={order.main_image}
                          alt=""
                        />
                      </div>

                      <div className="w-full ml-5 space-y-1">
                        <p
                          className="font-semibold cursor-pointer"
                          onClick={() =>
                            handleProductRedirect(order.product_id)
                          }
                        >
                          {order.product_name}
                        </p>
                        <div className="flex items-start justify-start">
                          <p className="mr-5 opacity-70">
                            Price: ₹{order.price}
                          </p>
                          <p className="opacity-70">Size: {order.size}</p>
                        </div>

                        <p className="opacity-70">
                          {" "}
                          Quantity: {order.quantity}
                        </p>

                        <div
                          className="flex items-center justify-between w-full"
                          style={{ marginTop: "16px" }}
                        >
                          <p className="text-green-600 text-[16px]">
                            Expected Delivery: {formatDate(order.delivery_date)}
                          </p>

                          <div className="px-4 py-2 bg-[#E85826] ">
                            <p className="font-semibold tracking-wide text-white capitalize">
                              {order.order_status}
                            </p>
                          </div>
                        </div>
                        <p className="opacity-70 text-[12px]">
                          Order Date: {formatDate(order.created_at)}
                        </p>
                      </div>
                    </div>
                  </div>
                ))
            )}
          </div>
        )}
        {/* Addresses */}
        {activeTab === "addresses" && (
          <div className="lg:w-[80%] w-full lg:mt-0 mt-10">
            <form onSubmit={handleAddressFormSubmit}>
              <Grid container spacing={3}>
                <Grid xs={12} sm={12} item>
                  <TextField
                    required
                    id="address"
                    name="address"
                    label="Address"
                    fullWidth
                    value={addressData.address}
                    onChange={handleAddressInputChange}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#EDB92B",
                        },
                      },
                      "& .MuiInputLabel-root.Mui-focused": {
                        color: "#EDB92B",
                      },
                    }}
                  />
                </Grid>
                <Grid xs={12} sm={6} item>
                  <TextField
                    required
                    id="city"
                    name="city"
                    label="City"
                    fullWidth
                    value={addressData.city}
                    onChange={handleAddressInputChange}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#EDB92B",
                        },
                      },
                      "& .MuiInputLabel-root.Mui-focused": {
                        color: "#EDB92B",
                      },
                    }}
                  />
                </Grid>

                <Grid xs={12} sm={6} item>
                  <TextField
                    required
                    id="state"
                    name="state"
                    label="State/Region"
                    fullWidth
                    value={addressData.state}
                    onChange={handleAddressInputChange}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#EDB92B",
                        },
                      },
                      "& .MuiInputLabel-root.Mui-focused": {
                        color: "#EDB92B",
                      },
                    }}
                  />
                </Grid>

                <Grid xs={12} sm={6} item>
                  <TextField
                    required
                    id="zip"
                    name="zip"
                    label="Zip/Postal code"
                    fullWidth
                    value={addressData.zip}
                    onChange={handleAddressInputChange}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#EDB92B",
                        },
                      },
                      "& .MuiInputLabel-root.Mui-focused": {
                        color: "#EDB92B",
                      },
                    }}
                  />
                </Grid>

                <Grid xs={12} sm={6} item>
                  <TextField
                    required
                    id="country"
                    name="country"
                    label="Country"
                    fullWidth
                    value={addressData.country}
                    onChange={handleAddressInputChange}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#EDB92B",
                        },
                      },
                      "& .MuiInputLabel-root.Mui-focused": {
                        color: "#EDB92B",
                      },
                    }}
                  />
                </Grid>
              </Grid>

              <div className="flex items-center justify-center mt-10">
                <button
                  className="w-[10rem] h-[3rem] bg-[#E85826] hover:bg-[#A5320C] rounded-lg text-white font-[22px] text-center font-semibold"
                  type="submit"
                >
                  <span>Save Changes</span>
                </button>
              </div>
            </form>
          </div>
        )}

        {/* Help Center */}
        {activeTab === "helpCenter" && (
          <div className="mt-5 lg:mt-0">
            <div className="flex items-center gap-[2rem]">
              <p className="text-[24px] tracking-wide">
                Get quick customer support
              </p>
              <img
                src="https://sa-web-h1a.flixcart.com/mosaic/ss/customer-support-64640718.svg?q=80"
                alt=""
              />
            </div>

            <div className="mt-10">
              <div className="flex items-center gap-[1rem] mb-5">
                <img src={whatsAppIcon} alt="" className="w-[2rem] h-[2rem]" />
                <p>+91 9325656679</p>
              </div>

              <div className="flex items-center gap-[1rem] mb-5">
                <img src={telegramIcon} alt="" className="w-[2rem] h-[2rem]" />
                <p>@TheRoyalMan_EthnicWardrobe</p>
              </div>

              <div className="flex items-center gap-[1rem] mb-5">
                <img src={telephoneIcon} alt="" className="w-[2rem] h-[2rem]" />
                <p>+91 9325656679</p>
              </div>

              <div className="flex items-center gap-[1rem] mb-5">
                <img src={mailIcon} alt="" className="w-[2rem] h-[2rem]" />
                <p>theroyalmanethnic@gmail.com</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default MyProfile;
