import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ProductCard from "../ProductCard/ProductCard";
import colors from "../../styles/color";
import MoreProductsBtn from "../MoreProductsBtn/MoreProductsBtn";
import Features from "../Features/Features";


const HomeMidSection = () => {
  const navigate = useNavigate();
  const [newArrivals, setNewArrivals] = useState([]);
  const [CategoryImage, setCategoryImage] = useState([]);

  

    // Fetch New Arrivals products from API
    useEffect(() => {

      const CategoryImage = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND_API_URL}/api/product/category-images`);
          const data = await response.json();
          
          
          setCategoryImage(data.data);
        } catch (error) {
          console.error("Error fetching products:", error);
        }
      };
  
      CategoryImage();

      
      const fetchProducts = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND_API_URL}/api/product/new-arrivals`);
          const data = await response.json();
          
          setNewArrivals(data.data);
          
        } catch (error) {
          console.error("Error fetching products:", error);
        }
      };
  
      fetchProducts();
    }, []);

    


    
 
  
  const handleMoreProducts = () => {
    navigate("/products");
  };

  var settings = {
    dots: true,
    accessibility: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplaySpeed: 1000,
    autoplay: true,
    centerMode: true,
    draggable: false,
    easing: "linear",
    focusOnSelect: true,
    swipeToSlide: false,
  };



  const handleCategoryClick = (categoryName) => {
    navigate(`/category/${categoryName}`); 
    
  };

  return ( 
    <div className="lg:mt-[7rem] mt-[2rem] bg-gradient-to-b from-[#FFFBF2] to-[#F8ECD7]  w-full">
      <h1
        className="text-[#010001] font-normal lg:text-[32px] text-[20px] tracking-wide text-center"
        style={{ fontFamily: "Lora, serif" }}
      >
        WHAT'S YOUR VIBE?
      </h1>

      <div className="lg:mt-[3rem] mt-4 w-full mx-auto overflow-hidden">
      <Slider {...settings}>
        {CategoryImage.map((product) => (
          <div 
            key={product.product_id} 
            className="w-full cursor-pointer" 
            onClick={() => handleCategoryClick(product.category_name)}
          >
            <img 
              src={product.category_imageurl} 
              alt='category'
              className="z-0"
              role="presentation"
            />
          </div>
        ))}
      </Slider>
    </div>

      <div className="pt-10" style={{ backgroundColor: colors.HomeMidBg }}>
        <h1
          className="text-[#010001] font-normal lg:text-[32px] text-[20px] tracking-wide text-center lg:mt-[7rem]"
          style={{ fontFamily: "Lora, serif" }}
        >
          New Arrivals
        </h1>

        <div className="flex flex-wrap items-center justify-center mt-[2rem] lg:gap-0 gap-8  pb-[2rem]">
          {newArrivals.map((product) => (
            <div key={product.product_id} className="w-[1/2] lg:w-[23%] lg:mb-8">
              <Link to={`/products/${product.product_id}`}>
                <ProductCard product={product} />
              </Link>
            </div>
          ))}
        </div>

        <div className="flex items-center justify-center mx-auto pb-[3rem] sm:mt-[1.5rem]" onClick={handleMoreProducts}>
          <MoreProductsBtn />
        </div>
      </div>

      <div className="pb-[3rem]" style={{ backgroundColor: colors.HomeMidBg }}>
        <Features />
      </div>
    </div>
  );
};

export default HomeMidSection;
