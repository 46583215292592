import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Navbar/Navbar";
import { useLocation } from "react-router-dom";
import Footer from "../../Components/Footer/Footer";
import ProductCard from "../../Components/ProductCard/ProductCard";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import SEO from "../../SEO/seo";

const ProductPage = () => {
  const [allProducts, setAllProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  // const [error, setError] = useState(null);

  const location = useLocation();

  const sortOptions = [
    { name: "Most Popular", href: "#", current: true },
    { name: "Newest", href: "#", current: false },
    { name: "Price: Low to High", href: "#", current: false },
    { name: "Price: High to Low", href: "#", current: false },
  ];

  const [currentSort, setCurrentSort] = useState(sortOptions[0]);

  const handleOptionClick = (option) => {
    setCurrentSort(option);
    sortProducts(option.name);
  };

  const sortProducts = (sortType) => {
    if (sortType === "Most Popular") {
      allProducts.sort((a, b) => a.product_id - b.product_id);
    } else if (sortType === "Newest") {
      allProducts.sort((a, b) => b.product_id - a.product_id);
    } else if (sortType === "Price: Low to High") {
      allProducts.sort((a, b) => a.actual_price - b.actual_price);
    } else if (sortType === "Price: High to Low") {
      allProducts.sort((a, b) => b.actual_price - a.actual_price);
    }
  };

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setLoading(true);

        // Helper function to extract search parameter
        const getSearchQuery = () => {
          const params = new URLSearchParams(location.search);
          return params.get("search") || "";
        };

        const searchQuery = getSearchQuery();
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_API_URL}/api/product/all-products?search=${searchQuery}`
        );
        const data = await response.json();

        setAllProducts(data.data);
      } catch (error) {
        console.error("Error fetching products:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();

    window.scrollTo(0, 0);
  }, [location.search]);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <div>
      <SEO
        title="The Royal Man Ethnic Wardrobe | Sherwanis and Traditional Attire"
        description="Explore our exclusive collection of traditional Sherwanis, Jodhpuri suits, Indo-Westerns, and ethnic wear at HAIDER Ethnics. Perfect for weddings, special occasions, and cultural events. Discover elegance and heritage with The Royal Man."
      />

      <Navbar />
      <div>
        {/* if searchQuery has no results show this message */}
        {allProducts.length === 0 && (
          <div className="flex items-center justify-center w-full h-[50vh]">
            <h1 className="text-2xl">No results found</h1>
          </div>
        )}

        {allProducts.length !== 0 && (
          <div className="flex items-center justify-between w-full sm:px-[2.5rem] p-4 py-4 mt-5">
            {/* <h1 className='text-center'>{category}</h1> */}
            <div>
              <p className="block sm:hidden"> {allProducts.length} Results</p>
            </div>
            <div className="flex items-center w-[30%] justify-end gap-6 mr-6 sm:mr-0">
              <p className="hidden sm:block"> {allProducts.length} Results</p>
              <Menu as="div" className="relative inline-block text-left">
                <div className="bg-[#FFF7E7] w-[8rem] h-[2rem] border border-gray-400 rounded-sm text-center ">
                  <Menu.Button className="inline-flex justify-end font-semibold text-[20px] text-gray-600 group hover:text-gray-700 gap-3">
                    Sort
                    <ChevronDownIcon
                      className="flex-shrink-0 -mr-1 text-gray-600 ml-1px w-[2rem] group-hover:text-gray-700"
                      aria-hidden="true"
                    />
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 w-40 mt-2 origin-top-right bg-[#F9F3E6] rounded-md shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      {sortOptions.map((option) => (
                        <Menu.Item key={option.name}>
                          {({ active }) => (
                            <a
                              href={option.href}
                              onClick={() => handleOptionClick(option)}
                              className={classNames(
                                option.current
                                  ? "font-medium text-gray-900"
                                  : "text-gray-500",
                                active ? "bg-[#ede4d1]" : "",
                                "block px-4 py-2 text-sm cursor-pointer"
                              )}
                            >
                              {option.name}
                            </a>
                          )}
                        </Menu.Item>
                      ))}
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        )}
        <div className="grid grid-cols-2 lg:grid-cols-3 lg:p-[3rem]  justify-center items-center gap-5 lg:gap-10 lg:w-full w-[90%] mx-auto">
          {allProducts.map((product) => (
            <div key={product.product_id}>
              <ProductCard product={product} />
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProductPage;
