import React from "react";
import { FiEdit } from "react-icons/fi";

const ProductCard = ({ product, handleProductDelete, handleProductEdit }) => {
  return (
    <div className="w-full mt-5 lg-mt-0">
      <div className="p-5 border rounded-md shadow-sm bg-[#FFFBF2]">
        <div className="flex items-start space-x-4">
          <div className="w-[5rem] h-[5rem] lg:w-[9rem] lg:h-[9rem]">
            <img
              className="object-cover object-top w-full h-full"
              src={product.main_image}
              alt=""
            />
          </div>

          <div className="ml-5 space-y-1 ">
            <p className="font-semibold">{product.product_name}</p>
            {/* <p className="opacity-70">₹{product.actual_price}</p> */}

            <div
              className="flex items-center pb-8 space-x-2 text-gray-900"
              style={{ marginTop: "16px" }}
            >
              <p className="font-semibold">₹{product.actual_price}</p>
              <p className="line-through opacity-50">
                ₹{product.product_price}
              </p>
              <p className="font-semibold text-green-600">
                {product.discount}% Off
              </p>
            </div>

            <div className="flex items-center justify-between w-1/2" >
              <button className="px-8 py-2 text-center bg-[#D0D2D7] hover:bg-gray-400 text-gray-800 rounded-sm shadow-md text-[16px] font-semibold mr-5" onClick={()=>handleProductEdit(product.product_id)}>Edit</button>
              <button className="px-8 py-2 text-center bg-[#E85826] hover:bg-[#A5320C] rounded-sm shadow-md text-white text-[16px] font-semibold mr-5" onClick={()=>handleProductDelete(product.product_id)}>Delete</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
