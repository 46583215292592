import React, { useState, Fragment } from "react";
import { Dialog, Transition, Menu } from "@headlessui/react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { MdOutlineMailOutline } from "react-icons/md";
import { TfiTwitterAlt } from "react-icons/tfi";
import { FaFacebookF } from "react-icons/fa";
import { LuPhone } from "react-icons/lu";
// import { FaLinkedin } from "react-icons/fa";
import { PiInstagramLogoFill } from "react-icons/pi";
import { FaYoutube } from "react-icons/fa";
// import { FaPinterest } from "react-icons/fa";
import RegisterLogin from "../RegisterLogin/RegisterLogin";
import { logout } from "../../utils/authService";
import "./Navbar.css";
// import {
//   ChevronDownIcon,
//   FunnelIcon,
//   MinusIcon,
//   PlusIcon,
//   Squares2X2Icon,
// } from "@heroicons/react/20/solid";
import { RiArrowDropDownLine } from "react-icons/ri";
import { XMarkIcon } from "@heroicons/react/24/outline";
import colors from "../../styles/color";
import logo from "../../Assets/images/logo.png";
import logo01 from "../../Assets/images/logo-1.png";
import logo02 from "../../Assets/images/logo-2.png";
import { IoIosSearch } from "react-icons/io";
import { IoIosHeartEmpty } from "react-icons/io";
import { VscAccount } from "react-icons/vsc";
import { IoCartOutline } from "react-icons/io5";
import { GiHamburgerMenu } from "react-icons/gi";
import SearchBar from "../SearchBar/SearchBar";

const Navbar = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [activeSection, setActiveSection] = useState("menu");
  // const [showProducts, setShowProducts] = useState(false);
  // const [activeTab, setActiveTab] = useState("Home");
  const [seen, setSeen] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  // const [searchResults, setSearchResults] = useState([]);

  const token = localStorage.getItem("token");

  // const handleTabClick = (tab) => {
  //   setActiveTab(tab);
  // };

  const categories = [
    { name: "sherwani", href: "#", current: true },
    { name: "indowestern", href: "#", current: false },
    { name: "jodhpuri", href: "#", current: false },
    { name: "koti-kurta", href: "#", current: false },
    { name: "tuxedo", href: "#", current: false },
    { name: "suit", href: "#", current: false },
    { name: "kurta", href: "#", current: false },
    { name: "formals", href: "#", current: false },
  ];

  const handleSectionChange = (section) => {
    setActiveSection(section);
  };

  function togglePop() {
    setSeen(!seen);
  }
  function toggleSearch() {
    setShowSearch(!showSearch);
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const handleLogout = () => {
    logout();
  };

  const handleSearch = async (query) => {
    navigate(`/products?search=${encodeURIComponent(query)}`);
  };

  return (
    <div className="">
      {/* Hamburger Menu Start */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40 w-full lg:hidden"
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex flex-col pb-12 overflow-y-auto bg-white shadow-xl w-[80%]">
                <div className="flex px-4 pt-5 pb-2">
                  <button
                    type="button"
                    className="relative inline-flex items-center justify-center p-2 -m-2 text-gray-400 rounded-md"
                    onClick={() => setOpen(false)}
                  >
                    <span className="absolute -inset-0.5" />
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="w-6 h-6" aria-hidden="true" />
                  </button>
                </div>

                <div className="flex">
                  {/* Menu Section */}
                  <div className="flex items-center w-full p-4">
                    <div className="w-[50%]">
                      <button
                        onClick={() => handleSectionChange("menu")}
                        className={`text-center text-[24px] block p-2 mb-2 w-full text-left ${
                          activeSection === "menu"
                            ? "bg-[#F7F7F7] text-[#EDB92B] "
                            : "bg-[#ffffff]"
                        }`}
                        style={{ fontFamily: "Lora, serif" }}
                      >
                        Menu
                      </button>
                      <div
                        className={`h-1 w-full transition-all duration-300 ${
                          activeSection === "menu"
                            ? "bg-[#EDB92B]"
                            : "bg-transparent"
                        }`}
                      />
                    </div>

                    <div className="w-[50%]">
                      <button
                        onClick={() => handleSectionChange("products")}
                        className={`text-center text-[24px] block p-2 mb-2 w-full text-left ${
                          activeSection === "products"
                            ? "bg-[#F7F7F7] text-[#EDB92B] "
                            : "bg-[#ffffff]"
                        }`}
                      >
                        Products
                      </button>

                      <div
                        className={`h-1 w-full transition-all duration-300 ${
                          activeSection === "products"
                            ? "bg-[#EDB92B]"
                            : "bg-transparent"
                        }`}
                      />
                    </div>
                  </div>
                </div>
                {/* Content Section */}
                <div className="">
                  {activeSection === "menu" && (
                    <ul
                      className="p-5 ml-2 text-[24px]"
                      style={{ fontFamily: "Lora, serif" }}
                    >
                      <li className="p-3">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="p-3">
                        <Link to="/products">Products</Link>
                      </li>
                      <li className="p-3">
                        <Link to="/how-to-order">How to Order?</Link>
                      </li>

                      <li className="p-3">
                        <Link to="/contact">Contact Us</Link>
                      </li>

                      {token ? (
                        <>
                          <li className="p-3">
                            <Link to="/account">My Account</Link>
                          </li>
                          <li className="p-3" onClick={handleLogout}>
                            <Link>Log Out</Link>
                          </li>
                        </>
                      ) : (
                        <li className="p-3">
                          <Link onClick={togglePop}>Login / Register</Link>
                        </li>
                      )}
                    </ul>
                  )}

                  {activeSection === "products" && (
                    <ul
                      className="p-5 ml-5 text-[24px]"
                      style={{ fontFamily: "Lora, serif" }}
                    >
                      {categories.map((category) => (
                        <li
                          key={category.name}
                          className={`p-3 ${
                            category.current ? "font-bold" : ""
                          }`}
                        >
                          <a href={`/category/${category.name}`}>
                            {category.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  )}

                  <div
                    className="p-5 ml-2 text-[24px]"
                    style={{ fontFamily: "Lora, serif" }}
                  >
                    <hr
                      className="h-1 mt-5 mb-10 opacity-30"
                      style={{ backgroundColor: colors.NavHover }}
                    />

                    <h1 className="mb-5">CONTACT</h1>

                    <div className="flex items-center gap-4 mb-5 text-[18px] w-full">
                      <MdOutlineMailOutline />
                      <a
                        href="mailto:theroyalmanethnic@gmail.com"
                        className="overflow-hidden underline text-ellipsis whitespace-nowrap"
                        style={{ maxWidth: "80%" }}
                      >
                        theroyalmanethnic@gmail.com
                      </a>
                    </div>

                    <div className="flex text-[18px] items-center  gap-4">
                      <LuPhone />
                      <p>
                        +91 9325656679 <br />
                      </p>
                    </div>

                    <hr
                      className="h-1 mt-10 mb-10 opacity-30"
                      style={{ backgroundColor: colors.NavHover }}
                    />

                    <div className="flex items-center justify-center text-[28px] gap-7 ml-[-28px]">
                      <a
                        href="https://www.facebook.com/profile.php?id=100071908942294&mibextid=LQQJ4d "
                        style={{
                          color: colors.socialMediaIcon,
                          backgroundColor: colors.socialMediaIconBg,
                        }}
                      >
                        <FaFacebookF />
                      </a>
                      <a
                        href="https://x.com/TheroyalmanEW?t=Lx5tJjq2a5ENsPoW-l4kpg&s=09"
                        style={{
                          color: colors.socialMediaIcon,
                          backgroundColor: colors.socialMediaIconBg,
                        }}
                      >
                        <TfiTwitterAlt />
                      </a>
                      <a
                        href="https://www.instagram.com/the_royal_man_ethnic_wardrobe/profilecard/?igsh=YW54dTQzZTRvNXM4 "
                        style={{
                          color: colors.socialMediaIcon,
                          backgroundColor: colors.socialMediaIconBg,
                        }}
                      >
                        <PiInstagramLogoFill />
                      </a>
                      {/* <a href="" style={{color: colors.socialMediaIcon, backgroundColor: colors.socialMediaIconBg}}><FaLinkedin /></a> */}
                      <a
                        href="https://www.youtube.com/@TheRoyalManEthnicwardrobe"
                        style={{
                          color: colors.socialMediaIcon,
                          backgroundColor: colors.socialMediaIconBg,
                        }}
                      >
                        <FaYoutube />
                      </a>
                      {/* <a href="" style={{color: colors.socialMediaIcon, backgroundColor: colors.socialMediaIconBg}}><FaPinterest /></a> */}
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <nav
        className="w-full h-[6rem] flex items-center justify-between navbar"
        style={{ backgroundColor: colors.Navbackground }}
      >
        <div className="container flex items-center justify-between mx-auto font-semibold px-7">
          {/* hamburget menu start  */}
          <div
            className={`text-[16px] hd:hidden mr-5 `}
            style={{ color: colors.HamBurger }}
          >
            <button onClick={() => setOpen(true)}>
              <GiHamburgerMenu />
            </button>
          </div>

          {/* Logo Section */}
          <div className="cursor-pointer">
            <a href="/">
              <div className="flex items-center justify-center w-full ml-2 sm:ml-0">
                <img
                  src={logo01}
                  className=" w-[7rem] h-[5rem] hidden lg:block"
                  alt="logo"
                />
                <img src={logo02} className="h-[2.5rem] " alt="logo" />
              </div>
            </a>
          </div>
          {/* Navbar Links and Icons */}
          <div className="flex items-center gap-[4rem]">
            <div className="">
              {showSearch && (
                <SearchBar
                  toggleSearch={toggleSearch}
                  onSearch={handleSearch}
                />
              )}
            </div>
            <ul
              className={`hidden hd:flex items-center gap-[2rem] sm:text-md`}
              style={{ color: colors.NavMenuLinks, fontFamily: "Lora, serif" }}
            >
              <li>
                <a
                  href="/"
                  style={{ transition: "color 0.1s" }}
                  // onClick={() => handleTabClick("Home")}
                  // className={`${activeTab === "Home" ? "text-[#EDB92B]" : ""}`}
                  onMouseEnter={(e) => (e.target.style.color = colors.NavHover)}
                  onMouseLeave={(e) =>
                    (e.target.style.color = colors.NavMenuLinks)
                  }
                >
                  Home
                </a>
              </li>
              <li className="flex items-center">
                <div
                  className="flex items-center"
                  style={{ transition: "color 0.1s" }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.color = colors.NavHover;
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.color = colors.NavMenuLinks;
                  }}
                >
                  <Menu as="div" className="relative inline-block text-left">
                    <div className="">
                      <Menu.Button className="inline-flex justify-end gap-1">
                        Products
                        <a href="/products" className="text-[24px]">
                          <RiArrowDropDownLine />
                        </a>
                      </Menu.Button>
                    </div>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 w-40 mt-2 origin-top-right bg-[#F9F3E6] rounded-md shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          {categories.map((category) => (
                            <Menu.Item key={category.name}>
                              {({ active }) => (
                                <a
                                  href={`/category/${category.name}`}
                                  className={classNames(
                                    category.current
                                      ? "font-medium text-gray-900"
                                      : "text-gray-500",
                                    active ? "bg-[#ede4d1]" : "",
                                    "block px-4 py-2 text-sm"
                                  )}
                                >
                                  {category.name}
                                </a>
                              )}
                            </Menu.Item>
                          ))}
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </li>

              <li>
                <a
                  href="/how-to-order"
                  // onClick={() => handleTabClick("How to Order?")}
                  // className={`${activeTab === "How to Order?" ? "text-[#EDB92B]" : ""}`}
                  style={{ transition: "color 0.1s" }}
                  onMouseEnter={(e) => (e.target.style.color = colors.NavHover)}
                  onMouseLeave={(e) =>
                    (e.target.style.color = colors.NavMenuLinks)
                  }
                >
                  How to Order?
                </a>
              </li>
              <li>
                <a
                  href="/contact"
                  // onClick={() => handleTabClick("Contact Us")}
                  // className={`${activeTab === "Contact Us" ? "text-[#EDB92B]" : ""}`}
                  style={{ transition: "color 0.1s" }}
                  onMouseEnter={(e) => (e.target.style.color = colors.NavHover)}
                  onMouseLeave={(e) =>
                    (e.target.style.color = colors.NavMenuLinks)
                  }
                >
                  Contact Us
                </a>
              </li>
            </ul>

            {/* Navbar Icons */}
            <ul
              className={`font-semibold flex items-end justify-end lg:gap-[2rem] gap-2 text-[20px] lg:text-[28px]`}
              style={{ color: colors.NavIcons }}
            >
              <li className="cursor-pointer">
                <button
                  onClick={toggleSearch}
                  style={{ transition: "color 0.1s" }}
                  onMouseEnter={(e) => (e.target.style.color = colors.NavHover)}
                  onMouseLeave={(e) => (e.target.style.color = colors.NavIcons)}
                >
                  <IoIosSearch />
                </button>
              </li>
              <li>
                <button
                  style={{ transition: "color 0.1s" }}
                  onMouseEnter={(e) => (e.target.style.color = colors.NavHover)}
                  onMouseLeave={(e) => (e.target.style.color = colors.NavIcons)}
                  onClick={
                    token
                      ? () => (window.location.href = "/wishlist")
                      : togglePop
                  }
                >
                  <IoIosHeartEmpty />
                </button>
              </li>

              <li>
                <button
                  style={{ transition: "color 0.1s" }}
                  onMouseEnter={(e) => (e.target.style.color = colors.NavHover)}
                  onMouseLeave={(e) => (e.target.style.color = colors.NavIcons)}
                  onClick={
                    token ? () => (window.location.href = "/cart") : togglePop
                  }
                >
                  <IoCartOutline />
                </button>
              </li>
              <li className="hidden hd:block">
                <button
                  style={{
                    transition: "color 0.1s",
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                  }}
                  onMouseEnter={(e) => (e.target.style.color = colors.NavHover)}
                  onMouseLeave={(e) => (e.target.style.color = colors.NavIcons)}
                  onClick={
                    token
                      ? () => (window.location.href = "/account")
                      : togglePop
                  }
                >
                  <VscAccount />
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      {seen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[9999]">
          <div className="relative lg:p-[3rem] lg:pt-[1rem] p-5 pt-1 bg-[#FEFAF2] shadow-lg lg:w-[45%] lg:h-[80%] w-[95%]">
            <RegisterLogin togglePop={togglePop} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;
