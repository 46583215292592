import React from "react";
import measuringGuide from "../../Assets/images/measuring-guide.png";
import { IoLogoWhatsapp } from "react-icons/io";

const HowToOrder = () => {
  return (
    <div>
      <h1 className="text-[#A5320C] font-bold text-center m-5 text-[24px]">
        HOW TO ORDER{" "}
      </h1>

      <div className="lg:px-[6rem] py-[1rem] px-[2rem]">
        <p className="mb-4 ">
          Product चुने &gt; अपना साइज चुने &gt; Add to Cart करे &gt; Checkout पर
          Click करे &gt; अपना Billing Details और Address भरे &gt; Place Order पर
          Click करके Payment करे &gt; Order Place का Wait करे । ( 5-10 Sec )
          &gt; Order का Screenshot लीजिए &gt; Order Done !
        </p>
        <p className="mb-4">
          आपके ऑर्डर करने के 24 घंटों में हम आपसे WhatsApp द्वारा साइज Issue के
          बारे में बात करेंगे । यदि आपके चुने हुवे साइज से Outfit बनाना हो तो
          हमे Confim करे । यदि आपको Costumize साइज से बनाना हो तो…
        </p>
        <p className="mb-4">
          जिस्मे आपको किसी दर्ज़ी के पास जाके नीचे दिए गए Measuring Guide के
          हिसाब से आपना साइज WhatsApp करना है ।
        </p>
        <div className="flex items-center gap-3 mt-4">
          <IoLogoWhatsapp size={22} className="text-[#52CF5E]" />
          <p className=""> WhatsApp Us +91 9325656679</p>
        </div>
        <img src={measuringGuide} alt="measuring-guide" className="my-5" />
      </div>
    </div>
  );
};

export default HowToOrder;
