import React from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";

const PoliciesPage = () => {
  const { hash } = useLocation(); // Get the current hash from the URL

  useEffect(() => {
    // Scroll to the element if the hash exists
    if (hash) {
      const element = document.getElementById(hash.replace("#", ""));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [hash]);

  return (
    <>
      <Navbar />
      <div className="min-h-screen ">
        <div className="max-w-5xl px-4 py-12 mx-auto sm:px-6 lg:px-8">
          <h1 className="text-4xl font-extrabold text-center text-[#A5320C] mb-8 underline">
            Our Policies{" "}
          </h1>

          <section
            id="shipping-details"
            className="p-6 mb-10 rounded-lg shadow-lg"
          >
            <h2 className="text-2xl font-bold text-[#A5320C] mb-4 text-center">
              Shipping Details
            </h2>
            <p className="leading-relaxed text-gray-700">
              We aim to deliver your order as quickly and efficiently as
              possible.
              <br />- <span className="font-semibold">
                Processing Time:
              </span>{" "}
              Orders are typically processed within 1-2 business days.
              <br />- <span className="font-semibold">Shipping Time:</span>{" "}
              Depending on your location, shipping may take 3-7 business days
              within India.
              <br />- <span className="font-semibold">
                Shipping Costs:
              </span>{" "}
              Shipping charges vary based on the location and weight of the
              order, which will be calculated during checkout.
              <br />-{" "}
              <span className="font-semibold">Tracking Information:</span> Once
              your order has been dispatched, we will send you tracking details
              to monitor the status of your shipment.
            </p>
          </section>

          <section
            id="return-policy"
            className="p-6 mb-10 rounded-lg shadow-lg"
          >
            <h2 className="text-2xl font-bold text-[#A5320C] mb-4 text-center">
              Return and Exchange Policy
            </h2>
            <p className="leading-relaxed text-gray-700">
              At The Royal Man, we strive to deliver quality customized clothing
              tailored specifically for each customer. Please note that due to
              the personalized nature of our products, we are unable to offer
              returns, exchanges, or refunds once the order is placed and
              production has begun.
              <br />-{" "}
              <span className="font-semibold">No Return or Exchange:</span> All
              sales are final for customized or made-to-order items, as we cut
              and alter fabric uniquely for each customer.
              <br />-{" "}
              <span className="font-semibold">Non-returnable Items:</span>{" "}
              Customized items cannot be returned, exchanged, or refunded under
              any circumstances.
            </p>
          </section>

          <section id="terms-of-use" className="p-6 mb-10 rounded-lg shadow-lg">
            <h2 className="text-2xl font-bold text-[#A5320C] mb-4 text-center">
              Terms of Use
            </h2>
            <p className="leading-relaxed text-gray-700">
              By using our website, you agree to the following terms and
              conditions:
              <br />- <span className="font-semibold">Website Use:</span> The
              Royal Man grants you a limited license to access and make personal
              use of this website. You may not download or modify any portion of
              it without our written consent.
              <br />-{" "}
              <span className="font-semibold">Intellectual Property:</span> All
              content on the site, including logos, images, and text, is the
              property of The Royal Man. You may not reproduce or redistribute
              it without permission.
              <br />-{" "}
              <span className="font-semibold">
                Pricing and Availability:
              </span>{" "}
              Prices and availability of products are subject to change without
              notice.
              <br />-{" "}
              <span className="font-semibold">Limitation of Liability:</span> We
              will not be held liable for any damages arising from the use of
              our website or products.
            </p>
          </section>

          <section
            id="privacy-policy"
            className="p-6 mb-10 rounded-lg shadow-lg"
          >
            <h2 className="text-2xl font-bold text-[#A5320C] mb-4 text-center">
              Privacy Policy
            </h2>
            <p className="leading-relaxed text-gray-700">
              Your privacy is important to us. This policy explains how we
              collect, use, and protect your information:
              <br />-{" "}
              <span className="font-semibold">Information Collection:</span> We
              collect personal information such as your name, address, and
              payment details when you make a purchase.
              <br />- <span className="font-semibold">
                Use of Information:
              </span>{" "}
              Your information is used solely to process your orders, manage
              your account, and send you updates about our products and offers.
              <br />- <span className="font-semibold">Data Protection:</span> We
              implement appropriate security measures to protect your
              information against unauthorized access or disclosure.
              <br />-{" "}
              <span className="font-semibold">Third-Party Sharing:</span> We do
              not sell or share your information with third parties, except when
              necessary to process payments or deliver orders.
              <br />- <span className="font-semibold">Cookies:</span> Our
              website uses cookies to enhance your browsing experience and track
              usage patterns.
            </p>
          </section>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default PoliciesPage;
