import React, { useEffect } from "react";
import Navbar from "../../Components/Navbar/Navbar";
import HomeMidSection from "../../Components/HomeMidSection/HomeMidSection";
import HeroSection from "../../Components/HeroSection/HeroSection";
import Footer from "../../Components/Footer/Footer";
import SEO from "../../SEO/seo";

const HomePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <SEO title="The Royal Man Ethnic Wardrobe | Home" description="Discover a premium collection of traditional and contemporary men's wear including Sherwanis, Indo-Westerns, Kurtas, Tuxedos, and much more. Perfect for weddings, events, and celebrations." />
      <Navbar />
      <HeroSection />
      <HomeMidSection />
      <Footer />
    </div>
  );
};

export default HomePage;
