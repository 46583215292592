import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ProductDetails from "../../Components/ProductDetails/ProductDetails";
import Navbar from "../../Components/Navbar/Navbar";
import SEO from "../../SEO/seo";
import Footer from "../../Components/Footer/Footer";

const ProductDetailsPage = () => {
  const { productId } = useParams();
  const [productDetails, setProductDetails] = useState(null);

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_API_URL}/api/product/product-details?productId=${productId}`
        );
        const data = await response.json();

        if (!data.data || data.data.length === 0) return;

        const productData = data.data[0];
        const product = {
          name: productData.product_name || "Unknown Product",
          price: `₹${productData.actual_price}` || "₹0",
          breadcrumbs: [
            { id: 1, name: "Men" || "Unknown Category" },
            {
              id: 1,
              name: productData.category || "Unknown Category",
              href: `/category/${productData.category}`,
            },
          ],
          images: [
            {
              src: productData.main_image,
              alt: productData.product_name || "Product Image",
            },
            { src: productData.image_1, alt: "Additional image 1" },
            { src: productData.image_2, alt: "Additional image 2" },
            { src: productData.image_3, alt: "Additional image 3" },
          ].filter((image) => image.src), // Filter out empty images
          discount: productData.discount || 0,
          productPrice: productData.product_price || 0,
          sizes: productData.size || ["One Size"],
          description: productData.description || "No description available.",
          highlights: productData.highlights || ["No highlights available."],
          details: productData.details || "No details available.",
        };

        setProductDetails(product);
      } catch (error) {
        console.error("Error fetching product details:", error);
      }
    };

    fetchProductDetails();
    window.scrollTo(0, 0);
  }, [productId]);

  return (
    <div>
      <SEO
        title="Traditional Sherwani | The Royal Man Ethnic Wardrobe"
        description="Shop the exquisite Traditional Sherwani from HAIDER Ethnics. This elegant piece features intricate embroidery, perfect for weddings and celebrations. Available in various sizes and colors. Experience the blend of tradition and style!"
      />

      <Navbar />
      {productDetails ? (
        <ProductDetails productDetails={productDetails} />
      ) : (
        <p>Loading...</p>
      )}
      <Footer />
    </div>
  );
};

export default ProductDetailsPage;
