// Wishlist.js
import React, { useState, useEffect } from 'react';
import WishlistItem from './WishlistItem';

const Wishlist = () => {
  const [wishlistItems, setWishlistItems] = useState([]);

  const token = localStorage.getItem('token');

  useEffect(() => {

    if (token) {

      const fetchWishlist = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND_API_URL}/api/wishlist/get`, {
            headers: {
              'Authorization': `Bearer ${token}` 
            }
          });
          
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          setWishlistItems(data.data);
        } catch (error) {
          console.error('Error fetching wishlist', error);
        }
      };

      fetchWishlist();
    } else {
      console.error("No token found, user not authenticated");
    }
  }, [token]);

  // Handle deletion of a wishlist item
  const handleDelete = async (wishlistId) => {
    try {
  
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API_URL}/api/wishlist/delete/${wishlistId}`,
        {
          method: "DELETE",
          headers: {
            'Authorization': `Bearer ${token}`, 
          },
        }
      );
  
      if (response.status === 204) {
        setWishlistItems(wishlistItems.filter((item) => item.wishlist_id !== wishlistId));
      } else {
        console.error("Failed to delete the wishlist item");
      }
    } catch (error) {
      console.error("Error deleting wishlist item:", error);
    }
  };

  return (
    <div className='p-5'>
      <h1 className='text-[24px] font-semibold text-start mt-4 ml-5 mb-5 text-[#A5320C]'>
        My Wishlist ({wishlistItems.length})
      </h1>
      <div className='grid grid-cols-1 lg:grid-cols-2'>
        {wishlistItems
          .sort((a, b) => b.wishlist_id - a.wishlist_id) 
          .map((wishlistItem) => (
            <WishlistItem
              key={wishlistItem.wishlist_id}
              wishlistItem={wishlistItem}
              handleDelete={handleDelete} 
            />
          ))}
      </div>
    </div>
  );
};

export default Wishlist;
