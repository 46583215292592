import React, { useState } from "react";
import "./SearchBar.css";
import { IoIosSearch } from "react-icons/io";
import { IoMdClose } from "react-icons/io";

const SearchBar = ({ toggleSearch, onSearch }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearch = () => {
    if (searchTerm.trim()) {
      onSearch(searchTerm);
      toggleSearch();
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center w-full bg-black bg-opacity-50">
      <div className="bg-[#FEFBF3] rounded-sm shadow-lg py-4 px-4 sm:w-[60%] w-[90%] relative">
        {/* Close button */}
        <div className="flex items-center justify-between mb-4">
          <p>Type to search</p>
          <IoMdClose
            size={25}
            className="cursor-pointer"
            onClick={toggleSearch}
          />
        </div>

        {/* Search input */}
        <div className="flex items-center justify-between">
          <input
            type="text"
            id="search"
            value={searchTerm}
            onChange={handleInputChange}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSearch();
              }
            }}
            placeholder="Search for Kurtas, Sherwani, Indowestern..."
            className="bg-[#FEFBF3] w-full p-2 outline-none font-normal"
          />
          <IoIosSearch
            size={25}
            className="cursor-pointer right-5"
            onClick={handleSearch}
          />
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
