import React, { useState, useEffect } from "react";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import Cart from "../../Components/Cart/Cart";
import SEO from "../../SEO/seo";


const { Link } = require("react-router-dom");


const CartPage = () => {
  const token = localStorage.getItem("token");
  // const navigate = useNavigate();

  const [cartItems, setCartItems] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!token) {
      window.location.href = "/";
    }
  }, []);

  useEffect(() => {
    if (token) {
      const fetchCart = async () => {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_BACKEND_API_URL}/api/cart/get`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const data = await response.json();

          setCartItems(data.data);
        } catch (error) {
          console.error("Error fetching wishlist", error);
        }
      };

      fetchCart();
    } else {
      console.error("No token found, user not authenticated");
    }
  }, [cartItems, token]);

  // Remove product from cart
  const handleRemoveCartItem = async (cartId) => {
    if (token) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_API_URL}/api/cart/delete/${cartId}`,
          {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 204) {
          setCartItems(cartItems.filter((item) => item.cart_id !== cartId));
        } else {
          console.error("Failed to delete the cart item");
        }
      } catch (error) {
        console.error("Error deleting cart item:", error);
      }
    }
  };

  return (
    <div>
      <SEO
        title="Shopping Cart | The Royal Man Ethnic Wardrobe"
        description="Review your selected items in the cart at HAIDER Ethnics. Adjust quantities, apply promo codes, and proceed to checkout to secure your traditional and contemporary men's wear."
      />

      <Navbar />
      {cartItems.length === 0 ? (
        <div style={{ textAlign: "center", padding: "50px" }} className="">
          <h2 className="mb-2 font-bold text-md">Your cart is empty</h2>
          <p className="text-[18px] font-normal">
            There are no items in your cart. Let’s add something!
          </p>
          <Link to="/products">
            <div className="w-[15rem] h-[4rem] bg-[#E85826] hover:bg-[#A5320C] text-center mx-auto mt-10 rounded-sm flex items-center justify-center my-auto">
              <button
                type="button"
                className="my-auto font-semibold tracking-wide text-white text[24px]"
              >
                CONTINUE SHOPPING
              </button>
            </div>
          </Link>
        </div>
      ) : (
        <Cart
          cartItems={cartItems}
          handleRemoveCartItem={handleRemoveCartItem}
        />
      )}

      <Footer />
    </div>
  );
};

export default CartPage;
