import React from "react";
import { Fragment, useState } from "react";
import {Menu, Transition } from "@headlessui/react";
import {
  ChevronDownIcon,
} from "@heroicons/react/20/solid";
// import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import ProductCard from "../ProductCard/ProductCard";

const Categories = ({ categoryName, categoryProduct }) => {
  const sortOptions = [
    { name: "Most Popular", href: "#", current: true },
    { name: "Newest", href: "#", current: false },
    { name: "Price: Low to High", href: "#", current: false },
    { name: "Price: High to Low", href: "#", current: false },
  ];

  const [currentSort, setCurrentSort] = useState(sortOptions[0]);
  
  

  const handleOptionClick = (option) => {
    setCurrentSort(option);
    // Here, trigger your sorting logic
    sortProducts(option.name);
  };

  const sortProducts = (sortType) => {
    
    if (sortType === "Most Popular") {
      categoryProduct.sort((a, b) => a.product_id - b.product_id);
    } else if (sortType === "Newest") {
      categoryProduct.sort((a, b) => b.product_id - a.product_id);
    } else if (sortType === "Price: Low to High") {
      categoryProduct.sort((a, b) => a.actual_price - b.actual_price);
    } else if (sortType === "Price: High to Low") {
      categoryProduct.sort((a, b) => b.actual_price - a.actual_price);
    }
  };


  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <div className="sm:p-[3rem] p-0">
      <div className="flex items-center justify-between w-full sm:px-[2.5rem] p-4 py-4">
        {/* <h1 className='text-center'>{category}</h1> */}
        <div>
          <h1 className="text-[24px] font-semibold text-[#A5320C]">
            {`${categoryName.charAt(0).toUpperCase() + categoryName.slice(1)}s`}
          </h1>

          <p className="block sm:hidden"> {categoryProduct.length} Results</p>
        </div>
        <div className="flex items-center w-[30%] justify-end gap-6 mr-6 sm:mr-0">
          <p className="hidden sm:block"> {categoryProduct.length} Results</p>
          <Menu as="div" className="relative inline-block text-left">
            <div className="bg-[#FFF7E7] w-[8rem] h-[2rem] border border-gray-400 rounded-sm text-center ">
              <Menu.Button className="inline-flex justify-end font-semibold text-[20px] text-gray-600 group hover:text-gray-700 gap-3">
                Sort
                <ChevronDownIcon
                  className="flex-shrink-0 -mr-1 text-gray-600 ml-1px w-[2rem] group-hover:text-gray-700"
                  aria-hidden="true"
                />
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10 w-40 mt-2 origin-top-right bg-[#F9F3E6] rounded-md shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  {sortOptions.map((option) => (
                    <Menu.Item key={option.name}>
                      {({ active }) => (
                        <a
                        href={option.href}
                        onClick={() => handleOptionClick(option)}
                          className={classNames(
                            option.current
                              ? "font-medium text-gray-900"
                              : "text-gray-500",
                            active ? "bg-[#ede4d1]" : "",
                            "block px-4 py-2 text-sm cursor-pointer"
                          )}
                        >
                          {option.name}
                        </a>
                      )}
                    </Menu.Item>
                  ))}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>

      <div>
        <div className="grid grid-cols-2 lg:grid-cols-3 sm:p-[3rem] p-[1rem] justify-center items-center gap-10">
          {categoryProduct.map((product) => (
            <Link to={`/products/${product.product_id}`} key={product.product_id} className="block">
              <ProductCard product={product} />
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Categories;
