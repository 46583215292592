

export const logout = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch( `${process.env.REACT_APP_BACKEND_API_URL}/api/user/logout`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
  
      if (response.ok) {
        localStorage.removeItem('token'); 
        
        window.location.href = '/'; 
      } else {
        alert('Failed to logout');
      }
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };
  