import React, { useState } from "react";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const CartItem = ({ cartItem, handleRemoveCartItem }) => {
  const [quantity, setQuantity] = useState(cartItem.quantity);

  const updateCartQuantity = async (cart_id, newQuantity) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_API_URL}/api/cart/update-quantity`, {
        method: 'PUT', 
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
          cart_id: cart_id,
          quantity: newQuantity,
        }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to update cart quantity');
      }
  
      // const result = await response.json();
    } catch (error) {
      console.error('Error updating cart quantity:', error);
    }
  };
  

  // Function to handle increment
  const handleIncrement = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
    updateCartQuantity(cartItem.cart_id, quantity + 1);
  };

  // Function to handle decrement
  const handleDecrement = () => {
    if (quantity > 1) {
      setQuantity((prevQuantity) => prevQuantity - 1);
      updateCartQuantity(cartItem.cart_id, quantity - 1);
    }
  };

  
  

  return (
    <div className="p-5 m-5 border rounded-md shadow-sm bg-[#FFFBF2]">
      <div>
        <div className="flex items-center space-x-4">
          <div className="w-[5rem] h-[5rem] lg:w-[9rem] lg:h-[9rem]">
            <img
              className="object-cover object-top w-full h-full"
              src={cartItem.main_image}
              alt="cart item"
            />
          </div>

          <div className="ml-5 space-y-1">
            <p className="font-semibold">{cartItem.product_name}</p>
            <p className="opacity-70">Size: {cartItem.size}</p>

            <div
              className="flex items-center space-x-2 text-gray-900"
              style={{ marginTop: "16px" }}
            >
              <p className="font-semibold">₹{cartItem.actual_price}</p>
              <p className="line-through opacity-50">
                ₹{cartItem.product_price}
              </p>
              <p className="font-semibold text-green-600">
                {cartItem.discount}% Off
              </p>
            </div>
          </div>
        </div>
        {/* rgb(79 70 229 / var(--tw-bg-opacity)) */}
        <div className="items-center pt-6 lg:flex lg:space-x-10">
          <div className="flex items-center justify-center space-x-4 opacity-70">
            <RemoveCircleOutlineIcon
              onClick={handleDecrement}
              sx={{ color: "#E14E1D", cursor: "pointer" }}
            />
            <span className="py-1 border rounded-sm px-7 border-[#E14E1D]">
                {quantity}
            </span>
            <AddCircleOutlineIcon
              onClick={handleIncrement}
              sx={{ color: "#E14E1D", cursor: "pointer" }}
            />
            <div>
              <button
                type="button"
                onClick={() => handleRemoveCartItem(cartItem.cart_id)}
                className="ml-10 font-semibold"
                style={{ color: "#E14E1D" }}
              >
                Remove
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartItem;
