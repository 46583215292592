const colors = {
    Navbackground: '#501D01',
    // Navbackground: '#FBF3E3',
    NavMenuLinks: '#ffffff',
    // NavMenuLinks: '#6B665D',
    // NavIcons: '#ffffff',
    NavIcons: '#ffffff',
    // NavIcons: '#6B665D',
    NavHover: '#EDB92B',
    // HamBurger: '#3E3B36',
    HamBurger: '#ffffff',
    socialMediaIcon: '#E04F1D',
    socialMediaIconBg:'#FEF2DD',
    BodyBg: '#FFFBF2',
    HomeMidBg: '#F8ECD7',
    moreProductsBg: '#E14E1D',
    moreProductsHover: '#501D01',
    footerBg: '#501D01',
  };
  
  export default colors;
  