import React from "react";
import colors from "../../styles/color";

const MoreProductsBtn = () => {
  return (
    <div>
      <button
        className="h-[2rem] w-[10rem] rounded-lg text-white sm:text-[16px] text-[14px] font-normal text-center hover:text-[#501D01]"
        style={{ backgroundColor: colors.moreProductsBg }}
      >
        More Products
      </button>
    </div>
  );
};

export default MoreProductsBtn;
